import { FeatureFlag } from 'controllers/_exports';

export const imageBasePath = process.env.REACT_APP_BASE_IMAGE_URL;
export const videoBasePath = process.env.REACT_APP_BASE_VIDEO_URL;
export const fileBasePath = process.env.REACT_APP_BASE_FILE_URL;

export const regExpression = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  url: /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+([-]*)+[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
  password: /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9]).{8,}$/,
  digits: /^(0|[1-9][0-9]{0,9})$/,
  simpleUrl:
    /^(https?:\/\/)?([\w-])+\.{1}([a-zA-Z]{2,63})([/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)$/,
};

export const contentTypes = [
  {
    id: 0,
    label: 'default',
    platforms: [],
    extensions: [],
    icon: 'link.svg',
  },
  {
    id: 1,
    label: 'Video',
    platforms: [
      'youtube.com',
      'youtu.be',
      'loom.com',
      'vimeo.com',
      'video/quicktime',
      'video/mp4',
      'video/ogg',
    ],
    extensions: [
      '.WEBM',
      '.MPG',
      '.MP2',
      '.MPEG',
      '.MPE',
      '.MPV',
      '.OGG',
      '.MP4',
      '.M4P',
      '.M4V',
      '.MOV',
      '.QT',
      '.AVI',
      '.MKV',
    ],
    icon: 'video.svg',
  },
  {
    id: 2,
    label: 'Audio',
    platforms: [
      'podcast.co',
      'soundtrap.com',
      'soundcloud.com',
      'anchor.fm',
      'audio/mpeg',
      'audio/wav',
      'audio/mp3',
      'audio/mp4',
    ],
    extensions: ['.mp3', '.wav'],
    icon: 'audio.svg',
  },
  {
    id: 3,
    label: 'PDF',
    platforms: ['application/pdf'],
    extensions: ['.pdf'],
    icon: 'pdf.svg',
  },
  {
    id: 5,
    label: 'XLS',
    platforms: ['spreadsheet', 'text/csv', 'text/xls', 'text/ods', 'text/tsv', 'text/xlsx'],
    extensions: ['.xls', '.csv', '.ods', '.tsv', '.xlsx'],
    icon: 'xls.svg',
  },
  {
    id: 7,
    label: 'PPT',
    platforms: ['presentation'],
    extensions: ['.pptx'],
    icon: 'ppt.svg',
  },
  {
    id: 4,
    label: 'Doc',
    platforms: ['docs', 'document', 'officedocument'],
    extensions: ['.docx', '.odt', '.rtf'],
    icon: 'word.svg',
  },
  {
    id: 6,
    label: 'File',
    platforms: ['text', 'application/json', 'text/plain'],
    extensions: ['.txt', '.pdf', '.docx', '.odt', '.rtf'],
    icon: 'txt.svg',
  },
  {
    id: 8,
    label: 'Image',
    platforms: ['image/png', 'image/jpeg', 'image/jpg', 'image/svg'],
    extensions: ['.jpg', '.jpeg', '.png', '.svg'],
    icon: 'image.svg',
  },
];

export const reactSelectStyles = ({ errorValues = [], optionBackground }) => ({
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? '#E7F0F5'
        : isFocused
        ? '#E7F0F5'
        : optionBackground || null,
      color: isDisabled ? '#ccc' : isSelected ? '#415766' : '#415766',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      'font-weight': '400',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? '#E7F0F5' : '#E7F0F5'),
      },
    };
  },
  control: (styles, { isFocused, isSelected, isDisabled }) => ({
    ...styles,
    background: isFocused ? '#E7F0F5' : '#FFFFFF',
    // boxShadow: isFocused ? '0 0 0 1px #17C3B2' : 'none',
    boxShadow: 'none',
    border: isFocused ? '1px solid #DEE2ED' : '1px solid #DEE2ED',
    ':hover': {
      border: isFocused ? '1px solid #DEE2ED' : '1px solid #DEE2ED',
      background: isFocused ? '#E7F0F5' : '#FFFFFF',
    },
  }),
  multiValue: (styles, { data }) => {
    if (errorValues.includes(data?.value))
      return {
        ...styles,
        background: 'none',
        border: '1px solid #FBB9B9',
      };
    return {
      ...styles,
      backgroundColor: '#E7F0F5',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: '#415766',
    fontSize: 14,
    fontWeight: 400,
    padding: '0px 10px 0px 10px',
  }),
  multiValueRemove: (styles, { data }) => {
    return {
      ...styles,
      color: '#415766',
      ':hover': {
        backgroundColor: errorValues.includes(data?.value) ? 'transparent' : '#E7F0F5',
        color: '#415766',
      },
    };
  },
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  valueContainer: (styles) => ({
    ...styles,
    minHeight: '32px',
  }),
  singleValue: (styles) => ({
    ...styles,
    fontSize: 14,
    fontWeight: 400,
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    padding: '0 8px',
    color: '#415766',
    // background: '#EEF1F6;',
    // height: '38px',
    // borderRadius: '0px',
  }),
  menuPortal: (styles) => ({ ...styles, minHeight: '1px !important' }),
});

export const roles = () => {
  if (FeatureFlag.enabled('RENAME_SALES_LEAD')) {
    return [
      {
        id: 1,
        label: 'Owner',
        slug: 'primary_admin',
        value: 1,
      },
      {
        id: 2,
        label: 'Admin',
        slug: 'admin',
        value: 2,
      },
      {
        id: 3,
        label: 'Sales Management',
        slug: 'sales_lead',
        value: 3,
      },
      {
        id: 4,
        label: 'Sales Rep',
        slug: 'user',
        value: 4,
      },
    ];
  } else {
    return [
      {
        id: 1,
        label: 'Owner',
        slug: 'primary_admin',
        value: 1,
      },
      {
        id: 2,
        label: 'Admin',
        slug: 'admin',
        value: 2,
      },
      {
        id: 3,
        label: 'Sales Lead',
        slug: 'sales_lead',
        value: 3,
      },
      {
        id: 4,
        label: 'Sales Rep',
        slug: 'user',
        value: 4,
      },
    ];
  }
};

export const userStatus = [
  {
    id: 1,
    label: 'Active',
    value: 1,
  },
  {
    id: 2,
    label: 'Invited',
    value: 0,
  },
  {
    id: 3,
    label: 'Deactivated',
    value: 2,
  },
];

const likeStatus = [
  {
    id: 0,
    label: '',
  },
  {
    id: 1,
    label: 'Liked',
  },
  {
    id: 2,
    label: 'Disliked',
  },
];

export const goalFrequencies = [
  {
    id: 1,
    label: `Daily`,
    singleLable: 'day',
  },
  {
    id: 2,
    label: `Weekly`,
    singleLable: 'week',
  },
  {
    id: 3,
    label: `Monthly`,
    singleLable: 'month',
  },
  {
    id: 4,
    label: `Quarterly`,
    singleLable: 'quarter',
  },
];

export const goalUnits = [
  {
    id: 1,
    label: ``,
    icon: `hash_icon.svg`,
    iconBlue: 'hash_icon_blue.svg',
    iconGreen: 'hash_icon_green.svg',
  },
  {
    id: 2,
    label: `$`,
    icon: `revenue_icon.svg`,
    iconBlue: 'dollar_icon_blue.svg',
    iconGreen: 'dollor_icon_green.svg',
  },
];

export const checkinUnits = [
  {
    id: 1,
    label: '',
    img: 'hash_icon.svg',
    imgBlue: 'hash_icon_blue.svg',
    imgGreen: 'hash_icon_green.svg',
  },
  {
    id: 2,
    label: '$',
    img: 'revenue_icon.svg',
    imgBlue: 'dollar_icon_blue.svg',
    imgGreen: 'dollor_icon_green.svg',
  },
];

export const tooltipTexts = {
  training: `This metric shows the percentage of completed modules by all members of the organization.`,
  winrate: `This metric shows the percentage of leads that have been converted into customers.`,
  average: `This metric shows the average value of contracts across all customers.`,
  ramp_time: `This metric shows the average time from the start of onboarding to the completion of training and the first check-in for sales reps on the activity.`,
  onboarding_completion: `This metric shows the percentage of onboarding completion by all members of organization`,
  roleplay_completion: `This metric shows the percentage of assigned roleplay completion by all sales reps of organization`,
};

export const months = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' },
];

function generateYearArray() {
  const years = [];
  for (let i = 0; i <= 15; i++) {
    let year = i + new Date().getFullYear();
    years.push({ value: year, label: year.toString() });
  }
  return years;
}

export const years = generateYearArray();

export const optionsRecurring = [
  { value: 'None', label: 'None' },
  { value: 1, label: 'Weekly' },
  { value: 2, label: 'Monthly' },
  { value: 3, label: 'Quarterly' },
  { value: 4, label: 'Half-yearly' },
  { value: 5, label: 'Annually' },
  { value: 'Custom', label: 'Custom' },
];

export const optionsFrequency = [
  { value: 6, label: 'Days' },
  { value: 7, label: 'Weeks' },
  { value: 8, label: 'Months' },
];

export const rolesLPLead = () => {
  if (FeatureFlag.enabled('RENAME_SALES_LEAD')) {
    return [
      {
        id: -1,
        label: 'All Users',
        slug: 'all_users',
        value: -1,
      },
      {
        id: 3,
        label: 'Sales Management',
        slug: 'sales_lead',
        value: 3,
      },
      {
        id: 4,
        label: 'Sales Rep',
        slug: 'sales_rep',
        value: 4,
      },
    ];
  } else {
    return [
      {
        id: -1,
        label: 'All Users',
        slug: 'all_users',
        value: -1,
      },
      {
        id: 3,
        label: 'Sales Lead',
        slug: 'sales_lead',
        value: 3,
      },
      {
        id: 4,
        label: 'Sales Rep',
        slug: 'sales_rep',
        value: 4,
      },
    ];
  }
};

export const rolesLPAdmin = () => {
  if (FeatureFlag.enabled('RENAME_SALES_LEAD')) {
    return [
      {
        id: -1,
        label: 'All Users',
        slug: 'all_users',
        value: -1,
      },
      {
        id: 2,
        label: 'Admin',
        slug: 'admin',
        value: 2,
      },
      {
        id: 3,
        label: 'Sales Management',
        slug: 'sales_lead',
        value: 3,
      },
      {
        id: 4,
        label: 'Sales Rep',
        slug: 'sales_rep',
        value: 4,
      },
    ];
  } else {
    return [
      {
        id: -1,
        label: 'All Users',
        slug: 'all_users',
        value: -1,
      },
      {
        id: 2,
        label: 'Admin',
        slug: 'admin',
        value: 2,
      },
      {
        id: 3,
        label: 'Sales Lead',
        slug: 'sales_lead',
        value: 3,
      },
      {
        id: 4,
        label: 'Sales Rep',
        slug: 'sales_rep',
        value: 4,
      },
    ];
  }
};

export const timeOptions = [
  {
    id: 1,
    label: 'All Time',
    slug: 'all-time',
    value: 1,
  },
  {
    id: 2,
    label: 'Last 1 Year',
    slug: 'last-1-year',
    value: 2,
  },
  {
    id: 3,
    label: 'YTD',
    slug: 'YTD',
    value: 3,
  },
  {
    id: 4,
    label: 'Last 3 Months',
    slug: 'last-3-months',
    value: 4,
  },
];
