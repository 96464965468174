import { useEffect, useMemo } from 'react';
import Organization from 'infrastructure/Organization';
import { SnackbarContent, withStyles, Snackbar } from '@material-ui/core';
import { ChevronRight, Forum, CheckCircle } from '@material-ui/icons';
import sparkle from 'assets/icons/sparkle.svg';
import classes from './Autorating.module.scss';
import { useAutoFillStore } from './AutoFill.store';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import { useGetAssignmentRatingQuery } from 'features/roleplay/Roleplay.queries';
import { useShouldShowAutoRating } from 'features/roleplay/RoleplayHooks';
const AutoFillSnackbar = () => {
  const isEnabled = useShouldShowAutoRating();

  const filledWithAI = useAutoFillStore((state) => state?.filledWithAI);
  const startEditAI = useAutoFillStore((state) => state?.startEditAI);
  const toggleStartEditAI = useAutoFillStore((state) => state?.toggleStartEditAI);
  const saveEdition = useAutoFillStore((state) => state?.saveEdition);
  const statusAI = useAutoFillStore((state) => state?.statusAI);
  const setStatus = useAutoFillStore((state) => state?.setStatus);
  const setFeedbackAI = useAutoFillStore((state) => state?.setFeedbackAI);
  const responseData = useAutoFillStore((state) => state?.responseData);
  const setTempReview = useAutoFillStore((state) => state?.setTempReview);
  const reset = useAutoFillStore((state) => state?.reset);
  const isError = useAutoFillStore((state) => state?.isError);
  const undoFeedback = useAutoFillStore((state) => state?.undoFeedback);
  const toggleUndoFeedback = useAutoFillStore((state) => state?.toggleUndoFeedback);

  const formDataScenarioReview = useRoleplayStore((state) => state?.formDataScenarioReview);
  const setFormDataScenarioReview = useRoleplayStore((state) => state?.setFormDataScenarioReview);
  const reviewScenarioSelected = useRoleplayStore((state) => state?.reviewScenarioSelected);
  const roleplayView = useRoleplayStore((state) => state?.roleplayView);
  const org_id = Organization.getSelectedOrg()?.id;

  const responseScenarios = useMemo(() => {
    if (roleplayView.scenarios.length === 0) return [];
    return roleplayView.scenarios.map((item) => {
      if (item.response_retake === null) {
        return { scenario_id: item?.uuid, response_id: item?.response?.uuid };
      } else {
        return { scenario_id: item?.uuid, response_id: item?.response_retake?.uuid };
      }
    });
  }, [roleplayView?.scenarios]);

  const { data, isLoading } = useGetAssignmentRatingQuery({
    org_id,
    assignment_id: roleplayView?.uuid,
    responseScenarios,
    enabled: isEnabled && undoFeedback,
  });

  useEffect(() => {
    if (!data || !filledWithAI || responseData?.length === 0) return;

    const index = responseData?.findIndex((s) => s?.scenario_id === reviewScenarioSelected?.uuid); //get scenario id

    if (index !== -1 && index !== undefined) {
      //filled according to the scenario
      const { status, feedback, rating_criteria } = responseData[index] || {};

      const resetForm = () => {
        formDataScenarioReview?.rating_criterials_uuid?.forEach((criteria) => {
          criteria.stars = 0;
          criteria.reasoning = null;
          criteria.improvement_suggestion = null;
        });
        setFormDataScenarioReview({ ...formDataScenarioReview, comment: '' });
      };

      const updateRatingCriteria = () => {
        rating_criteria !== null
          ? rating_criteria.forEach((c) => {
              const idx = formDataScenarioReview.rating_criterials_uuid.findIndex(
                (criteria) => criteria.rating_criteria_uuid === c.criteria_id
              );
              if (idx !== -1) {
                formDataScenarioReview.rating_criterials_uuid[idx].stars = c.rating;
                formDataScenarioReview.rating_criterials_uuid[idx].reasoning = c.reasoning ?? null;
                formDataScenarioReview.rating_criterials_uuid[idx].improvement_suggestion =
                  c.improvement_suggestion ?? null;
              }
            })
          : formDataScenarioReview?.rating_criterials_uuid?.forEach((criteria) => {
              criteria.stars = 0;
              criteria.reasoning = null;
              criteria.improvement_suggestion = null;
            });
      };

      switch (status) {
        case 'IN_PROGRESS':
        case 'UNRATED':
          setFeedbackAI(feedback ?? '');
          setStatus(status);
          resetForm();
          break;

        case 'FAILED':
          if (feedback === null && rating_criteria === null) {
            setStatus('NOT_FOUND');
            setFeedbackAI('');
          } else {
            setStatus(status);
            setFeedbackAI(feedback);
          }
          resetForm();
          break;

        case 'SUCCEEDED':
          setStatus(status);
          updateRatingCriteria();
          setFormDataScenarioReview({
            ...formDataScenarioReview,
            comment: feedback ?? '',
          });
          break;

        default:
          break;
      }
    }

    if (index === -1) {
      if (responseData.length === 1 && responseData[0].status === 'WITHOUT_SCENARIOS') {
        setStatus('WITHOUT_SCENARIOS');
      } else {
        formDataScenarioReview?.rating_criterials_uuid?.forEach((criteria) => {
          criteria.stars = 0;
          criteria.reasoning = null;
          criteria.improvement_suggestion = null;
        });
        setFormDataScenarioReview({ ...formDataScenarioReview, comment: '' });
        setStatus('NOT_FOUND');
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseData, filledWithAI, reviewScenarioSelected, data]);

  const autoFill = () => toggleUndoFeedback();

  const editAIRating = () => {
    toggleStartEditAI();
    setTempReview({ ...formDataScenarioReview }); //make a copy of formDataScenarioReview
  };

  const handleResetAI = () => {
    toggleUndoFeedback();
    reset();
    formDataScenarioReview?.rating_criterials_uuid?.forEach((criteria) => {
      criteria.stars = 0;
      criteria.reasoning = null;
      criteria.improvement_suggestion = null;
    });
    setFormDataScenarioReview({ ...formDataScenarioReview, comment: '' });
  };

  const action = (
    <div
      style={{ display: 'flex', gap: '0.2rem', alignItems: 'center', cursor: 'pointer' }}
      className={classes.action}
      onClick={autoFill}
    >
      <span className={classes.actionText}>Auto Fill</span>
      <ChevronRight style={{ fontSize: '0.9rem', color: '#0494FD' }} />
    </div>
  );

  const FillSnackbarStyled = withStyles((theme) => ({
    root: {
      backgroundColor: '#ECF7FF',
      border: '1px solid  #0494FD',
      color: '#0494FD',
      boxShadow: 'none',
      padding: '1px 12px',
      marginBottom: '1rem',
      flexWrap: 'nowrap',
      [theme.breakpoints.up('md')]: {
        minWidth: '238px',
      },
    },
  }))(SnackbarContent);

  const EditSnackbarStyled = withStyles((theme) => ({
    root: {
      backgroundColor: 'white',
      border: '1px solid  #90A4AE',
      color: '#272D37',
      boxShadow: 'none',
      padding: '1px 12px',
      marginBottom: '1rem',
      flexWrap: 'nowrap',
      [theme.breakpoints.up('md')]: {
        minWidth: '238px',
      },
    },
  }))(SnackbarContent);

  const editAction = (
    <div
      style={{
        display: 'flex',
        gap: '0.2rem',
        alignItems: 'center',
        cursor: 'pointer',
        color: '#0494FD',
      }}
      className={classes.action}
      onClick={editAIRating}
    >
      <span>Edit</span>
      <ChevronRight style={{ fontSize: '0.9rem', color: '#0494FD' }} />
    </div>
  );

  if (!isEnabled) return null;

  return (
    <>
      {!undoFeedback &&
        !filledWithAI &&
        (reviewScenarioSelected?.response?.rating_criterials.length === 0 ||
          reviewScenarioSelected?.response_retake?.rating_criterials.length === 0) && (
          <FillSnackbarStyled
            message={
              <div className={classes.message}>
                <Forum className={classes.iconChat} style={{ fontSize: '0.9rem' }} />
                <div>AI-Powered Feedback is available</div>
              </div>
            }
            action={action}
          />
        )}
      {filledWithAI &&
        !startEditAI &&
        !saveEdition &&
        statusAI === 'SUCCEEDED' &&
        (reviewScenarioSelected?.response?.rating_criterials.length === 0 ||
          reviewScenarioSelected?.response_retake?.rating_criterials.length === 0) && (
          <EditSnackbarStyled
            message={
              <div className={classes.message}>
                <img src={sparkle} alt="sparkle" style={{ width: '1rem', height: '1rem' }} />
                <div style={{ fontSize: '12px', color: '#272D37' }}>
                  AI Rating Applied. Adjust the rating as needed
                </div>
              </div>
            }
            action={editAction}
          />
        )}
      {saveEdition && (
        <Snackbar
          open={saveEdition}
          style={{ zIndex: '999999', marginTop: '2rem' }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          className={classes.undoSnackbar}
          message={
            <div className={classes.message}>
              <CheckCircle className={classes.iconCheck} />
              <div>Feedback updated</div>
            </div>
          }
          action={
            <span className={classes.action} onClick={handleResetAI} style={{ cursor: 'pointer' }}>
              Undo
            </span>
          }
        />
      )}
      {filledWithAI && statusAI !== 'SUCCEEDED' && !isLoading && !isError && (
        <Snackbar
          open
          style={{ zIndex: '999999', marginTop: '2rem' }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          className={classes.undoSnackbar}
          message={
            <div className={classes.message}>
              <CheckCircle className={classes.iconCheck} />
              <div>Feedback updated</div>
            </div>
          }
          action={
            <span className={classes.action} onClick={handleResetAI} style={{ cursor: 'pointer' }}>
              Undo
            </span>
          }
        />
      )}
    </>
  );
};

export default AutoFillSnackbar;
