import { useMemo } from 'react';
import { useShouldShowIAFeature } from 'hooks/IAFeaturesHook';
import Organization from 'infrastructure/Organization';
import { useGetFF } from 'hooks/useGetFF';

export const useShouldShowChatAssist = () => useShouldShowIAFeature('sales_assist');

export const useShouldShowChatAssistRedirect = () => {
  const org_id = Organization.getSelectedOrg()?.slug;
  const { isEnabled, value, loading } = useGetFF({ flag_id: 'sales_assist' });
  const ai_integration = Organization.getSelectedOrg()?.ai_integration;

  const enabled = useMemo(() => {
    if (isEnabled && ai_integration && value !== '') {
      if (!value?.checkBySlug) return true;
      return value?.slugs?.includes(org_id) ?? false;
    }
    return false;
  }, [isEnabled, ai_integration, org_id, value]);

  return { loading, enabled };
};
