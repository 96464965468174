import React, { useMemo } from 'react';
import { TooltipCustom } from 'utils';
import { imageBasePath } from 'constants';

const ModulesRequiredIcon = ({ user }) => {
  console.log(user);
  const label = useMemo(() => {
    if (user.is_training_enabled && user.is_onboarding_enabled)
      return 'Onboarding and Training required';
    else if (user.is_training_enabled && !user.is_onboarding_enabled) return 'Training required';
    else if (!user.is_training_enabled && user.is_onboarding_enabled) return 'Onboarding required';
    else return 'Onboarding and Training not required';
  }, [user]);

  const icon = useMemo(() => {
    if (user.is_training_enabled && user.is_onboarding_enabled) return 'Onboarding.active.svg';
    else if (user.is_training_enabled && !user.is_onboarding_enabled) return 'Onboarding.idle.svg';
    else if (!user.is_training_enabled && user.is_onboarding_enabled) return 'Onboarding.idle.svg';
    else return 'Onboarding.disabled.svg';
  }, [user]);
  if (!(user.user_role === 3 || user.user_role === 4)) return;
  return (
    <TooltipCustom
      placement="top"
      arrow
      title={
        <div className="supportText" style={{ color: `#fff` }}>
          {label}
        </div>
      }
    >
      <img
        src={imageBasePath + icon}
        alt={label}
        style={{
          padding: '1px',
          width: '14px',
          height: '14px',
          transform: 'translate(5px, 4px)',
        }}
      />
    </TooltipCustom>
  );
};

export default ModulesRequiredIcon;
