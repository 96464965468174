import React, { useState, useEffect, useMemo } from 'react';
import styles from './RoleplayView.module.scss';
import { imageBasePath } from 'constants';
import { useLocation, useHistory } from 'react-router-dom';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import DropdownListScenarios from './DropdownListScenarios';
import { ProgressBar } from 'shared_ui_components';
import RecordVideo from '../RecordVideo/RecordVideo';
import RoleplayLabel from '../Common/RoleplayLabel';

const RoleplayViewContent = () => {
  const showRetake = useRoleplayStore((state) => state?.showRetake);
  const roleplayView = useRoleplayStore((state) => state?.roleplayView);
  let location = useLocation();
  let history = useHistory();
  const [scenarios, setScenarios] = useState([]);

  const handleBack = () => {
    history.push({
      pathname: `/roleplay`,
      state: {
        prevPath: `${location.pathname}${location.search ? location.search : ''}`,
      },
    });
  };

  useEffect(() => {
    setScenarios(roleplayView?.scenarios || []);
  }, [roleplayView]);

  const scenariosCompleted = useMemo(() => {
    const responses = scenarios.map((sc) =>
      Object.keys(sc?.response_retake || {})?.length > 0 ? sc?.response_retake : sc?.response
    );
    return responses.filter((res) => res && res?.video)?.length;
  }, [scenarios]);

  return (
    <div className={styles.background}>
      <div className={styles.roleplayHeader}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <button onClick={handleBack} className={styles.backBtn}>
            <img src={imageBasePath + 'back_arrow.svg'} alt="img" />
          </button>
          <p className="heading4">{roleplayView?.name || ''}</p>
          {showRetake && <RoleplayLabel {...roleplayView?.status} />}
        </div>
        {roleplayView?.scenarios[0].is_default === true ? null : ( // TODO check what happens if scenario name changes
          <>
            <DropdownListScenarios />
            <div>
              <span className={`${styles.value} heading6`}>
                {`${scenariosCompleted || 0}/${scenarios?.length || 0}`}{' '}
              </span>
              <span className="supportText" style={{ marginTop: 2 }}>
                Scenarios completed
              </span>
              <div>
                <ProgressBar
                  value={(scenariosCompleted * 100) / scenarios?.length}
                  max={100}
                  hideLabel={true}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div
        style={{
          marginTop: '1rem',
          maxHeight: '92%',
          flex: 1,
        }}
      >
        <RecordVideo />
      </div>
    </div>
  );
};

export default RoleplayViewContent;
