import { useState } from 'react';
import Papa from 'papaparse';
export const useImport = () => {
  // State to store parsed data
  const [parsedData, setParsedData] = useState([]);
  //State to store table Column name
  const [tableRows, setTableRows] = useState([]);
  //State to store the values
  const [questionValues, setQuestionValues] = useState([]);
  // Multiple choice question type uses rating criteria to save multiple choice options under the hood
  const isImport = ({ item }) => item?.answer_type?.value == 10 || item?.answer_type == 10;

  // Labels
  const needs_description_import = ({ item }) => !isImport({ item });
  const [fileTypeNoSupported, setFileTypeNoSupported] = useState(false);
  const [successLoad, setSuccessLoad] = useState(false);
  const [missingInformation, setMissingInformation] = useState(false);
  const [noInformationInFile, setNoInformationInFile] = useState(false);
  const closeMessages = () => {
    setSuccessLoad(false);
    setNoInformationInFile(false);
    setMissingInformation(false);
  };

  const validateData = (data) => {
    return data.every(
      (question) =>
        question.length >= 3 && question[0] !== '' && question[1] !== '' && question[2] !== ''
    );
  };
  const changeHandler = (event) => {
    setSuccessLoad(false);
    setNoInformationInFile(false);
    setMissingInformation(false);
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    if (event.target.files[0].type !== 'text/csv') {
      setFileTypeNoSupported(true);
      return;
    }
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const valuesArray = [];

        // Iterating data to get column name and their values
        results.data.map((d) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d).slice(0, 5));
        });
        if (valuesArray.length === 0) {
          setNoInformationInFile(true);
          return;
        } else if (!validateData(valuesArray)) {
          setMissingInformation(true);
          return;
        } else {
          // Parsed Data Response in array format
          setParsedData(results.data);
          // Filtered Column Names
          setTableRows(rowsArray[0].slice(0, 4));
          // Filtered Values
          setQuestionValues(valuesArray);
          setSuccessLoad(true);
        }
      },
    });
    setFileTypeNoSupported(false);
    // setSuccessLoad(true);
  };

  return {
    needs_description_import,
    isImport,
    changeHandler,
    questionValues,
    fileTypeNoSupported,
    successLoad,
    closeMessages,
    missingInformation,
    noInformationInFile,
  };
};
