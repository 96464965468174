import React, { useMemo, useEffect } from 'react';
import DescriptionRatingCriteria from './DescriptionRatingCriteria';
import DescriptionComment from './DescriptionComment';
import AutoFillSnackbar from '../Autorating/AutoFillSnackbar';
import { CustomButton } from 'shared_ui_components';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import { useAutoFillStore } from '../Autorating/AutoFill.store';
import { useReviewScenario } from 'features/roleplay/Roleplay.queries';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { STATUS_ID } from '../../RoleplayConstants';
import styles from './ReviewRoleplay.module.scss';
import { useShouldShowAutoRating } from 'features/roleplay/RoleplayHooks';
const ReviewRoleplayDescriptionRating = () => {
  let location = useLocation();
  let history = useHistory();
  const { uuid } = useParams();
  const isEnabled = useShouldShowAutoRating();

  const roleplayView = useRoleplayStore((state) => state?.roleplayView);
  const reviewScenarioSelected = useRoleplayStore((state) => state?.reviewScenarioSelected);
  const setReviewScenarioSelected = useRoleplayStore((state) => state?.setReviewScenarioSelected);
  const formDataScenarioReview = useRoleplayStore((state) => state?.formDataScenarioReview);
  const setFormDataScenarioReview = useRoleplayStore((state) => state?.setFormDataScenarioReview);
  const responseRetake = useRoleplayStore((state) => state?.responseRetake);
  const setResponseRetake = useRoleplayStore((state) => state?.setResponseRetake);
  const showSecondAttempt = useRoleplayStore((state) => state?.showSecondAttempt);
  const hideSecondAttempt = useRoleplayStore((state) => state?.hideSecondAttempt);

  const filledWithAI = useAutoFillStore((state) => state?.filledWithAI);
  const startEditAI = useAutoFillStore((store) => store?.startEditAI);
  const toggleStartEditAI = useAutoFillStore((state) => state?.toggleStartEditAI);
  const tempReview = useAutoFillStore((state) => state?.tempReview);
  const setTempReview = useAutoFillStore((state) => state?.setTempReview);
  const responseData = useAutoFillStore((state) => state?.responseData);
  const toggleSaveEdition = useAutoFillStore((state) => state?.toggleSaveEdition);
  const statusAI = useAutoFillStore((state) => state?.statusAI);
  const saveEdition = useAutoFillStore((state) => state?.saveEdition);

  const { mutate: reviewScenario } = useReviewScenario();
  const scenarioSelectedIndex = useMemo(
    () =>
      roleplayView?.scenarios.findIndex(
        (scenario) => scenario.uuid === reviewScenarioSelected?.uuid
      ),
    [roleplayView, reviewScenarioSelected]
  );

  if (!Object.keys(reviewScenarioSelected).length > 0)
    setReviewScenarioSelected({
      ...roleplayView?.scenarios[scenarioSelectedIndex + 1],
      label: `${scenarioSelectedIndex + 2}. ${
        roleplayView?.scenarios[scenarioSelectedIndex + 1].name
      }`,
    });

  const navigate = () => {
    history.push({
      pathname: `/roleplay-review/${uuid}/submit`,
      state: {
        prevPath: `${location.pathname}${location.search ? location.search : ''}`,
      },
    });
  };
  const buttonLabel = useMemo(() => {
    if (roleplayView?.scenarios.length === scenarioSelectedIndex + 1) return 'Continue';
    return 'Next';
  }, [roleplayView?.scenarios.length, scenarioSelectedIndex]);

  const rating = useMemo(() => {
    if (filledWithAI) return true;
    return false;
  }, [filledWithAI]);

  const handleFail = () => {
    const body = formDataScenarioReview?.rating_criterials_uuid.map((criteria) => {
      return isEnabled
        ? {
            rating_criteria_uuid: criteria.rating_criteria_uuid,
            stars: criteria.stars,
            reasoning: criteria?.reasoning,
            improvement_suggestion: criteria?.improvement_suggestion,
          }
        : { rating_criteria_uuid: criteria.rating_criteria_uuid, stars: criteria.stars };
    });
    const extraData = !showRetake ? { retake: true } : { pass: false };
    const scenarioResponse = isEnabled
      ? {
          rating_criterials_uuid: body,
          comment: formDataScenarioReview.comment,
          ...extraData,
          ai_raiting: rating,
        }
      : {
          rating_criterials_uuid: body,
          comment: formDataScenarioReview.comment,
          ...extraData,
        };
    reviewScenario(
      {
        roleplay: roleplayView.uuid,
        scenario: !showRetake ? reviewScenarioSelected.response.uuid : responseRetake.uuid,
        body: scenarioResponse,
      },
      {
        onSuccess: () => {
          formDataScenarioReview.rating_criterials_uuid.forEach(function (criteria) {
            criteria.stars = 0;
            criteria.reasoning = null;
            criteria.improvement_suggestion = null;
          });
          setResponseRetake({});
          hideSecondAttempt();
          setFormDataScenarioReview({ ...formDataScenarioReview, comment: '' });
          if (roleplayView?.scenarios.length !== scenarioSelectedIndex + 1)
            setReviewScenarioSelected({
              ...roleplayView?.scenarios[scenarioSelectedIndex + 1],
              label: `${scenarioSelectedIndex + 2}. ${
                roleplayView?.scenarios[scenarioSelectedIndex + 1].name
              }`,
            });
          if (roleplayView?.scenarios.length === scenarioSelectedIndex + 1) navigate();
        },
      }
    );
  };
  const handlePass = () => {
    const body = formDataScenarioReview?.rating_criterials_uuid.map((criteria) => {
      return isEnabled
        ? {
            rating_criteria_uuid: criteria.rating_criteria_uuid,
            stars: criteria.stars,
            reasoning: criteria?.reasoning,
            improvement_suggestion: criteria?.improvement_suggestion,
          }
        : { rating_criteria_uuid: criteria.rating_criteria_uuid, stars: criteria.stars };
    });

    const scenarioResponse = isEnabled
      ? {
          rating_criterials_uuid: body,
          comment: formDataScenarioReview.comment,
          pass: true,
          ai_raiting: rating,
        }
      : {
          rating_criterials_uuid: body,
          comment: formDataScenarioReview.comment,
          pass: true,
        };
    reviewScenario(
      {
        roleplay: roleplayView.uuid,
        scenario: !showRetake ? reviewScenarioSelected.response.uuid : responseRetake.uuid,
        body: scenarioResponse,
      },
      {
        onSuccess: () => {
          formDataScenarioReview.rating_criterials_uuid.forEach(function (criteria) {
            criteria.stars = 0;
            criteria.reasoning = null;
            criteria.improvement_suggestion = null;
          });
          setResponseRetake({});
          hideSecondAttempt();
          setFormDataScenarioReview({ ...formDataScenarioReview, comment: '' });
          if (roleplayView?.scenarios.length !== scenarioSelectedIndex + 1)
            setReviewScenarioSelected({
              ...roleplayView?.scenarios[scenarioSelectedIndex + 1],
              label: `${scenarioSelectedIndex + 2}. ${
                roleplayView?.scenarios[scenarioSelectedIndex + 1].name
              }`,
            });
          if (roleplayView?.scenarios.length === scenarioSelectedIndex + 1) navigate();
        },
      }
    );
  };

  const handleNext = () => {
    hideSecondAttempt();
    setResponseRetake({});
    if (roleplayView?.scenarios.length !== scenarioSelectedIndex + 1) {
      setFormDataScenarioReview({ ...formDataScenarioReview, comment: '' });
      setReviewScenarioSelected({
        ...roleplayView?.scenarios[scenarioSelectedIndex + 1],
        label: `${scenarioSelectedIndex + 2}. ${
          roleplayView?.scenarios[scenarioSelectedIndex + 1].name
        }`,
      });
    } else navigate();
  };

  const handlePrevious = () => {
    hideSecondAttempt();
    setResponseRetake({});
    try {
      const newScenario = roleplayView?.scenarios[scenarioSelectedIndex - 1];
      if (newScenario)
        setReviewScenarioSelected({
          ...newScenario,
          label: `${scenarioSelectedIndex}. ${newScenario.name}`,
        });
    } catch (error) {}
  };

  const disabled = useMemo(
    () =>
      formDataScenarioReview.comment === '' ||
      !formDataScenarioReview?.rating_criterials_uuid.every((item) => item.stars > 0)
        ? true
        : false,
    [formDataScenarioReview]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const seeNext = useMemo(() => {
    if (reviewScenarioSelected?.response?.rating_criterials.length > 0) return true;
    return false;
  }, [reviewScenarioSelected]);
  useEffect(() => {
    if (roleplayView?.status.id === STATUS_ID.RETAKEN) {
      const nextScenario = roleplayView?.scenarios.find(
        (sc) =>
          sc?.response?.rating_criterials?.length === 0 ||
          sc?.response_retake?.rating_criterials?.length === 0
      );
      if (!nextScenario) navigate();
      setReviewScenarioSelected({ ...nextScenario, label: `${nextScenario?.name}` });
    } else if (reviewScenarioSelected?.response?.rating_criterials?.length > 0) {
      const nextScenario = roleplayView?.scenarios.find(
        (sc) => sc?.response?.rating_criterials?.length === 0
      );
      if (!nextScenario) navigate();
      setReviewScenarioSelected({ ...nextScenario, label: `${nextScenario?.name}` });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const showRetake = useMemo(() => Object.keys(responseRetake).length > 0, [responseRetake]);

  const isRetakeQualified = useMemo(() => {
    if (Object.keys(reviewScenarioSelected).length > 0) {
      if (reviewScenarioSelected?.response_retake?.rating_criterials.length > 0) return true;
      else return false;
    } else return false;
  }, [reviewScenarioSelected]);

  const handleDiscard = () => {
    toggleStartEditAI();
    setTempReview({ comment: '' });
    const index = responseData?.findIndex((s) => s?.scenario_id === reviewScenarioSelected?.uuid);
    if (index !== -1 && index !== undefined) {
      responseData[index]?.rating_criteria.forEach((c) => {
        const index = formDataScenarioReview.rating_criterials_uuid.findIndex(
          (criteria) => criteria.rating_criteria_uuid === c.criteria_id
        );
        if (index !== -1) {
          formDataScenarioReview.rating_criterials_uuid[index].stars = c.rating;
          formDataScenarioReview.rating_criterials_uuid[index].reasoning = c.reasoning ?? null;
          formDataScenarioReview.rating_criterials_uuid[index].improvement_suggestion =
            c.improvement_suggestion ?? null;
          setFormDataScenarioReview({ ...formDataScenarioReview });
        }
      });

      setFormDataScenarioReview({
        ...formDataScenarioReview,
        comment: responseData[index]?.feedback,
      });
    }
  };

  const handleSaveRatingEditionAI = () => {
    setFormDataScenarioReview({ ...tempReview });
    toggleSaveEdition();
    toggleStartEditAI();
  };

  const isButtonDisabledByStatus = statusAI === 'IN_PROGRESS' || statusAI === 'FAILED';

  return (
    <div className={styles.descriptionRating}>
      <div>
        {reviewScenarioSelected?.response_retake === null ? (
          <AutoFillSnackbar />
        ) : (
          showRetake && <AutoFillSnackbar />
        )}
        <div>
          <DescriptionRatingCriteria />
          <DescriptionComment />
        </div>
      </div>
      {startEditAI ? (
        <div
          style={{
            display: 'flex',
            gap: '1rem',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
          }}
        >
          <CustomButton
            className="secondaryBtn"
            onClick={handleDiscard}
            style={{ color: '#E83A3A', border: '1px solid #E83A3A' }}
          >
            Discard
          </CustomButton>
          <CustomButton onClick={handleSaveRatingEditionAI} style={{ backgroundColor: '#2DC38D' }}>
            Save
          </CustomButton>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            gap: '1rem',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          {scenarioSelectedIndex > 0 ? (
            <CustomButton
              className="secondaryBtn"
              onClick={handlePrevious}
              disabled={startEditAI || saveEdition ? true : false}
            >
              Previous Scenario
            </CustomButton>
          ) : (
            <div> </div>
          )}
          <div
            style={{
              display: 'flex',
              gap: '1rem',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            {!seeNext || (showRetake && !isRetakeQualified) ? (
              <>
                <CustomButton
                  className="secondaryBtn"
                  onClick={handleFail}
                  disabled={disabled || isButtonDisabledByStatus}
                >
                  {showRetake ? 'Fail' : 'Retake'}
                </CustomButton>
                <CustomButton onClick={handlePass} disabled={disabled || isButtonDisabledByStatus}>
                  Pass
                </CustomButton>
              </>
            ) : isRetakeQualified ||
              showRetake ||
              reviewScenarioSelected?.response_retake === null ? (
              <CustomButton onClick={handleNext}>{buttonLabel}</CustomButton>
            ) : (
              <CustomButton
                onClick={() => {
                  setResponseRetake(reviewScenarioSelected?.response_retake);
                  showSecondAttempt();
                }}
              >
                Check 2nd Attempt
              </CustomButton>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewRoleplayDescriptionRating;
