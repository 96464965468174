import React, { useMemo } from 'react';
import { useAutoFillStore } from '../Autorating/AutoFill.store';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import RatingCriteriaTooltip from '../Common/RatingCriteriaTooltip';
import { useShouldShowAutoRating } from 'features/roleplay/RoleplayHooks';
import classes from './NewRatingCriteriaDescription.module.scss';
const RatingCriteriaHead = () => {
  const isEnabled = useShouldShowAutoRating();

  const filledWithAI = useAutoFillStore((state) => state?.filledWithAI);
  const statusAI = useAutoFillStore((state) => state?.statusAI);
  const showDetails = useAutoFillStore((state) => state?.showDetails);
  const openShowDetails = useAutoFillStore((state) => state?.openShowDetails);
  const closeShowDetails = useAutoFillStore((state) => state?.closeShowDetails);

  const formDataScenarioReview = useRoleplayStore((state) => state?.formDataScenarioReview);
  const reviewScenarioSelected = useRoleplayStore((state) => state?.reviewScenarioSelected);
  const responseRetake = useRoleplayStore((state) => state?.responseRetake);

  const showRetake = useMemo(() => Object.keys(responseRetake).length > 0, [responseRetake]);

  const isRetakeQualified = useMemo(() => {
    if (Object.keys(reviewScenarioSelected).length > 0) {
      if (reviewScenarioSelected?.response_retake?.rating_criterials.length > 0) return true;
      else return false;
    } else return false;
  }, [reviewScenarioSelected]);

  const areSuggestionsNull = useMemo(() => {
    return formDataScenarioReview?.rating_criterials_uuid.every(
      ({ reasoning, improvement_suggestion }) => {
        return reasoning === null && improvement_suggestion === null;
      }
    );
  }, [formDataScenarioReview]);

  if (!isEnabled) return <RatingCriteriaTooltip />;

  return !isRetakeQualified &&
    filledWithAI &&
    statusAI === 'SUCCEEDED' &&
    !areSuggestionsNull &&
    (reviewScenarioSelected?.response?.rating_criterials.length === 0 || showRetake) ? (
    <div className={classes.headContainer}>
      <RatingCriteriaTooltip />
      {showDetails ? (
        <div className={classes.action} onClick={() => closeShowDetails()}>
          Hide details
        </div>
      ) : (
        <div className={classes.action} onClick={() => openShowDetails()}>
          Show details
        </div>
      )}
    </div>
  ) : (
    <RatingCriteriaTooltip />
  );
};

export default RatingCriteriaHead;
