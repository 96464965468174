import React from 'react';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import { useAutoFillStore } from '../Autorating/AutoFill.store';
import { Rating } from '@material-ui/lab';
import { Collapse, withStyles } from '@material-ui/core';
import classes from './NewRatingCriteriaDescription.module.scss';
import classNames from 'classnames';

const NewDescriptionRatingCriteria = ({ editIA = false }) => {
  const formDataScenarioReview = useRoleplayStore((state) => state?.formDataScenarioReview);

  const statusAI = useAutoFillStore((state) => state?.statusAI);
  const tempReview = useAutoFillStore((state) => state?.tempReview);
  const setTempReview = useAutoFillStore((state) => state?.setTempReview);
  const showDetails = useAutoFillStore((state) => state?.showDetails);

  const handleEditStarAI = (value, uuid) => {
    const index = tempReview.rating_criterials_uuid.findIndex(
      (criteria) => criteria.rating_criteria_uuid === uuid
    );
    tempReview.rating_criterials_uuid[index].stars = value;
    setTempReview({ ...tempReview });
  };

  const StyledRating = withStyles({
    iconFilled: {
      color: '#EB9B42',
    },
    iconEmpty: {
      color: '#F9E1C6',
    },
  })(Rating);

  const StyledCollapse = withStyles({
    root: {
      border: showDetails ? '1px solid #CFD8DC' : null,
      borderRadius: showDetails ? '0.5rem' : null,
      padding: showDetails ? '5px 12px 14px 12px' : null,
      display: showDetails ? 'flex' : null,
      flexDirection: showDetails ? 'column' : null,
      gap: showDetails ? '1rem' : null,
    },
  })(Collapse);

  if (editIA)
    return (
      <div className={classNames({ [classes.globalContainer]: showDetails })}>
        {tempReview?.rating_criterials_uuid.map((x) => {
          return (
            <StyledCollapse
              in={showDetails}
              collapsedSize={25}
              key={`IA-Edit-${x.rating_criteria_uuid}`}
            >
              <div className={classes.startsContainer}>
                <p className="heading6" style={{ marginTop: '0.25rem' }}>
                  {x.name}
                </p>

                <StyledRating
                  name={x.rating_criteria_uuid}
                  value={x.stars}
                  onChange={(event, newValue) => {
                    handleEditStarAI(newValue, x.rating_criteria_uuid);
                  }}
                />
              </div>
              {x.reasoning ? (
                <div>
                  <p className="heading7Bold" style={{ marginTop: '0.25rem' }}>
                    Why this rating?
                  </p>
                  <p className="heading7" style={{ marginTop: '0.25rem' }}>
                    {x.reasoning}
                  </p>
                </div>
              ) : (
                <p className="heading7Bold" style={{ marginTop: '0.25rem' }}>
                  AI-Powered Why this rating? is not available
                </p>
              )}
              {x?.improvement_suggestion ? (
                <div>
                  <p className="heading7Bold" style={{ marginTop: '0.25rem' }}>
                    How to improve?
                  </p>
                  <p className="heading7" style={{ marginTop: '0.25rem' }}>
                    {x.improvement_suggestion}
                  </p>
                </div>
              ) : (
                <p className="heading7Bold" style={{ marginTop: '0.25rem' }}>
                  AI-Powered How to improve? is not available
                </p>
              )}
            </StyledCollapse>
          );
        })}
      </div>
    );

  return (
    <div className={classNames({ [classes.globalContainer]: showDetails })}>
      {formDataScenarioReview?.rating_criterials_uuid.map((criteria) => {
        return (
          <StyledCollapse in={showDetails} collapsedSize={25} key={criteria.rating_criteria_uuid}>
            <div className={classes.startsContainer}>
              <p className="heading6">{criteria.name}</p>
              {statusAI === 'SUCCEEDED' ? (
                <StyledRating
                  name={criteria.rating_criteria_uuid}
                  value={criteria.stars}
                  readOnly
                />
              ) : (
                <StyledRating name={criteria.rating_criteria_uuid} value={0} readOnly />
              )}
            </div>
            {criteria.reasoning ? (
              <div>
                <p className="heading7Bold" style={{ marginTop: '0.25rem' }}>
                  Why this rating?
                </p>
                <p className="heading7" style={{ marginTop: '0.25rem' }}>
                  {criteria.reasoning}
                </p>
              </div>
            ) : (
              <p className="heading7Bold" style={{ marginTop: '0.25rem' }}>
                AI-Powered Why this rating? is not available
              </p>
            )}
            {criteria?.improvement_suggestion ? (
              <div>
                <p className="heading7Bold" style={{ marginTop: '0.25rem' }}>
                  How to improve?
                </p>
                <p className="heading7" style={{ marginTop: '0.25rem' }}>
                  {criteria.improvement_suggestion}
                </p>
              </div>
            ) : (
              <p className="heading7Bold" style={{ marginTop: '0.25rem' }}>
                AI-Powered How to improve? is not available
              </p>
            )}
          </StyledCollapse>
        );
      })}
    </div>
  );
};

export default NewDescriptionRatingCriteria;
