import React, { useState, useEffect } from 'react';

import { Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import { CustomButton } from 'shared_ui_components';
import { useUsersStore } from '../../Users.store';
import { imageBasePath } from 'constants';
import { useUpdateUserSettingsMutation } from '../../Users.queries';
import SilentMode from './SilentMode';
import MandatoryModules from 'shared_elements/MandatoryModules';
import { useGetFF } from 'hooks/useGetFF';

const UserSettingsModal = ({ fetchAllUsers }) => {
  // Modal handlers
  const user = useUsersStore((s) => s?.selectedUser);
  const onClose = useUsersStore((s) => s?.deleteUserData);

  const handleClose = () => onClose();

  // Settings data
  const [settings, setSettings] = useState({});
  const handleSettingChange = (name, value) => {
    setSettings((ps) => ({ ...ps, [name]: value }));
  };
  useEffect(() => {
    if (!user) return;
    setSettings({
      silent_mode: user?.silent_mode,
      is_onboarding_enabled: user?.is_onboarding_enabled,
      is_training_enabled: user?.is_training_enabled,
    });
  }, [user]);

  // Save settings
  const { mutate, isLoading } = useUpdateUserSettingsMutation();
  const handleSave = () => {
    mutate({ settings, uuid: user?.user?.uuid }, { onSuccess });
  };

  const onSuccess = () => {
    fetchAllUsers();
    handleClose();
  };
  const { isEnabled: ff_mandatoryModules } = useGetFF({ flag_id: 'mandatory_modules' });
  if (!user) return null;
  return (
    <Dialog
      open={!!user}
      maxWidth="md"
      onClose={handleClose}
      className={'dsp-dialogs inviteUserDialog'}
    >
      <DialogTitle className="dialogTitle" id="scroll-dialog-title">
        <span className="heading2 title">User settings</span>
        <span className="heading5 description">{user?.user?.email}</span>
      </DialogTitle>
      <img className="featuredImg" src={imageBasePath + 'invite_user_img.png'} alt="" />
      <DialogContent>
        {ff_mandatoryModules && (
          <MandatoryModules
            settings={settings}
            onChangeOnboarding={(value) => handleSettingChange('is_onboarding_enabled', value)}
            onChangeTraining={(value) => handleSettingChange('is_training_enabled', value)}
          />
        )}
        <SilentMode
          settings={settings}
          onChange={(value) => handleSettingChange('silent_mode', value)}
        />
      </DialogContent>
      <DialogActions>
        <CustomButton disabled={isLoading} loading onClick={handleClose}>
          Cancel
        </CustomButton>
        <CustomButton disabled={isLoading} onClick={handleSave}>
          Save
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default UserSettingsModal;
