import React from 'react';
import { TooltipCustom } from 'utils';
import Icon from '@material-ui/icons/NotificationsOff';

const SilentModeIcon = ({ user }) => {
  return user.silent_mode ? (
    <TooltipCustom
      placement="top"
      arrow
      title={
        <div className="supportText" style={{ color: `#fff` }}>
          Silent mode
        </div>
      }
    >
      <Icon
        alt="silenced"
        style={{
          padding:"1px",
          width: '14px',
          height: '14px',
          opacity: '0.6',
          transform: 'translate(5px, 4px)',
          borderRadius: '50%',
        }}
      />
    </TooltipCustom>
  ) : null;
};

export default SilentModeIcon;
