export const msgInfo = { sender: 'USER', recipient: 'AI' };
export const tipsText = [
  { title: 'Start from scratch', text: 'Build something unique and original.' },
  { title: 'Powered by you', text: 'You are the engine of innovation.' },
  {
    title: 'Summarize your data',
    text: 'Simplify and distill key information for quick understanding.',
  },
  { title: 'Create with AI', text: 'Collaborate with AI to bring your ideas to life.' },
];

export const errorResponse = { text: 'Error getting a response', type: 'AI' };
