import { create } from 'zustand';

const initialState = {
  filledWithAI: false,
  startEditAI: false,
  saveEdition: false,
  statusAI: '',
  feedbackAI: '',
  tempReview: { comment: '' },
  responseData: [],
  isError: false,
  showDetails: false,
};

export const useAutoFillStore = create((set, get) => ({
  ...initialState,
  setFilledWithAI: () => set(() => ({ filledWithAI: true })),
  toggleStartEditAI: () => set((state) => ({ startEditAI: !state.startEditAI })),
  toggleSaveEdition: () => set((state) => ({ saveEdition: !state.saveEdition })),
  //temp for formDataScenarioReview
  setTempReview: (tempReview = {}) => set(() => ({ tempReview })),
  setStatus: (status) => set(() => ({ statusAI: status })),
  setFeedbackAI: (feedback) => set(() => ({ feedbackAI: feedback })),
  setResponseData: (responseData = []) => set(() => ({ responseData })),
  toggleIsError: () => set((state) => ({ isError: !state?.isError })),
  undoFeedback: true,
  toggleUndoFeedback: () => set((state) => ({ undoFeedback: !state.undoFeedback })),
  setUndoFeedback: () => set(() => ({ undoFeedback: true })),
  openShowDetails: () => set(() => ({ showDetails: true })),
  closeShowDetails: () => set(() => ({ showDetails: false })),
  reset: () => {
    set(initialState);
  },
}));
