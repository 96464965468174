import React, { useEffect, useMemo } from 'react';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import { InputSelect } from 'shared_ui_components';
import { useGetListUserRepQuery, useGetListUsersQuery } from 'features/roleplay/Roleplay.queries';
import User from 'infrastructure/User';
import { FeatureFlag } from 'controllers/_exports';
import styles from './AssignRoleplay.module.scss';
import RoleplayUserOption from './RoleplayUserOption';

const RoleplaySelectAssignRep = () => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  // Get users information
  const { data: usersList = [], isLoading } = useGetListUsersQuery();
  const { data = [] } = useGetListUserRepQuery({ uuid: userInfo?.user?.uuid });
  const roleplayAssignedToMe = useMemo(() => data, [data]);
  const scenariosList = useRoleplayStore((state) => state?.scenariosList);
  const formDataAssignRoleplay = useRoleplayStore((state) => state?.formDataAssignRoleplay);
  const setFormDataAssignRoleplay = useRoleplayStore((state) => state?.setFormDataAssignRoleplay);
  const isRoleplayAssignedToMe = useRoleplayStore((state) => state?.isRoleplayAssignedToMe);
  const showRoleplayAssignedToMe = useRoleplayStore((state) => state?.showRoleplayAssignedToMe);
  const hideRoleplayAssignedToMe = useRoleplayStore((state) => state?.hideRoleplayAssignedToMe);

  const options = useMemo(() => {
    if (!usersList?.length > 0) return [];
    return usersList.map((user) => {
      return {
        label: User.getUserName(user?.user),
        value: user.user.uuid,
        user: user?.user,
      };
    });
  }, [usersList]);

  useEffect(() => {
    const condition = roleplayAssignedToMe[0]?.role_plays?.some((role) => {
      const matchesRoleplay = role.uuid === formDataAssignRoleplay?.roleplay?.value;
      const selectedScenarios = scenariosList.filter((scenario) => scenario.isSelected);
      const matchDefaultScenario = role.scenarios[0].is_default === true;
      const matchesScenario =
        role.scenarios.length === selectedScenarios.length &&
        role.scenarios?.every((scenario) =>
          selectedScenarios?.some((selectedScenario) => selectedScenario.uuid === scenario.uuid)
        );
      return matchesRoleplay && (matchesScenario || matchDefaultScenario);
    });
    if (condition) {
      showRoleplayAssignedToMe();
    } else hideRoleplayAssignedToMe();
  }, [
    formDataAssignRoleplay,
    hideRoleplayAssignedToMe,
    roleplayAssignedToMe,
    scenariosList,
    showRoleplayAssignedToMe,
  ]);

  const handleChange = (newUsers) =>
    setFormDataAssignRoleplay({ ...formDataAssignRoleplay, users: newUsers });

  return (
    <div className={styles.selectAssignWrapper}>
      <InputSelect
        label={'Manager'}
        placeholder={isLoading ? 'Loading...' : 'Select Manager'}
        onChange={(values) => handleChange(values)}
        options={options}
        closeMenuOnSelect={true}
        noOptionsMessage={() => 'No managers found'}
        components={{ Option: RoleplayUserOption }}
        disabled={isLoading}
      />
      {FeatureFlag.enabled('ROLEPLAY_NEW_LOGIC_ASSIGN') && isRoleplayAssignedToMe > 0 && (
        <div className={styles.usersWithRoleplayContainer}>
          <p className={styles.userWRText}>
            This roleplay has already been assigned to you. To explore different options, please
            consider selecting alternative scenarios or roleplay to continue.
          </p>
        </div>
      )}
    </div>
  );
};

export default RoleplaySelectAssignRep;
