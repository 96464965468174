import React from 'react';
import ChatAssistView from '../components/ChatAssistView';
import { Redirect } from 'react-router-dom';
import { useShouldShowChatAssistRedirect } from 'features/chatAssist/ChatAssistHook';
const ChatAssist = () => {
  const { loading, enabled: isEnabled } = useShouldShowChatAssistRedirect();

  if (loading) return null;

  return isEnabled ? <ChatAssistView /> : <Redirect to="/" />;
};

export default ChatAssist;
