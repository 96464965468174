import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import ReviewRoleplayContent from './ReviewRoleplayContent';
import ReviewRoleplayDescription from './ReviewRoleplayDescription';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import { useGetRoleplayView } from 'features/roleplay/Roleplay.queries';
import { useParams } from 'react-router-dom';
import { useAutoFillStore } from '../Autorating/AutoFill.store';

const ReviewRoleplay = () => {
  const roleplayView = useRoleplayStore((state) => state?.roleplayView);
  const setRoleplayView = useRoleplayStore((state) => state?.setRoleplayView);
  const reviewScenarioSelected = useRoleplayStore((state) => state?.reviewScenarioSelected);
  const setReviewScenarioSelected = useRoleplayStore((state) => state?.setReviewScenarioSelected);
  const formDataScenarioReview = useRoleplayStore((state) => state?.formDataScenarioReview);
  const setFormDataScenarioReview = useRoleplayStore((state) => state?.setFormDataScenarioReview);
  const setResponseRetake = useRoleplayStore((state) => state?.setResponseRetake);
  const hideSecondAttempt = useRoleplayStore((state) => state?.hideSecondAttempt);

  const reset = useAutoFillStore((state) => state?.reset);
  const setUndoFeedback = useAutoFillStore((state) => state?.setUndoFeedback);

  const { uuid } = useParams();

  const { data, isLoading: isRoleplayViewLoading } = useGetRoleplayView({
    roleplay_id: uuid,
    enable: true,
  });

  useEffect(() => {
    if (!data) return;
    if (!data?.scenarios?.length > 0) return;
    setRoleplayView(data);
    if (!Object.keys(reviewScenarioSelected).length > 0)
      setReviewScenarioSelected({
        ...data.scenarios[0],
        label: `${1}. ${data.scenarios[0]?.name}`,
      });
    const rating_criterials_uuid = data?.rating_criteria.map((criteria) => {
      return {
        rating_criteria_uuid: criteria.uuid,
        name: criteria.name,
        description: criteria.description,
        reasoning: null,
        improvement_suggestion: null,
        stars: 0,
      };
    });
    setFormDataScenarioReview({ ...formDataScenarioReview, rating_criterials_uuid });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isRoleplayViewLoading]);

  useEffect(() => {
    return () => {
      setReviewScenarioSelected({});
      setRoleplayView({});
      setResponseRetake({});
      hideSecondAttempt();
      setFormDataScenarioReview({ comment: '' });
      reset();
      setUndoFeedback();
    };
  }, [
    setResponseRetake,
    setReviewScenarioSelected,
    setRoleplayView,
    hideSecondAttempt,
    setFormDataScenarioReview,
    reset,
    setUndoFeedback,
  ]);

  if (isRoleplayViewLoading || !roleplayView?.scenarios?.length > 0) return null;

  return (
    <>
      <Grid
        container
        direction="row"
        spacing={2}
        style={{ padding: '1rem 0 0 1rem', width: '100%', minHeight: '100%' }}
      >
        <Grid item xs={12} md={8}>
          <ReviewRoleplayContent />
        </Grid>
        <Grid item xs={12} md={4}>
          <ReviewRoleplayDescription />
        </Grid>
      </Grid>
    </>
  );
};

export default ReviewRoleplay;
