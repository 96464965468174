import { useState, useEffect, useContext } from 'react';
import { Grid, Avatar, Tooltip, Drawer, List, ListItem } from '@material-ui/core';
import { imageBasePath, regExpression } from '../constants';
import { GlobalContext } from '../context/GlobalContext';
import { CustomButton, InputText, InputSelect } from '../shared_ui_components';
import { fieldValidation } from '../utils/formValidation';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { globalPostService, globalGetService } from '../utils/globalApiServices';
import { toastFlashMessage, getTimezoneOptions } from '../utils';
import timezones from '../assets/timezone';
import NotificationSettings from '../features/home/components/NotificationSettings/NotificationSettings';
import { updateNotificationPreferences } from '../features/home/apiServices';
import { useGetFF } from 'hooks/useGetFF';

const countryRegion = [
  { value: '1', label: 'America' },
  { value: '2', label: 'Australia' },
  { value: '3', label: 'India' },
  { value: '4', label: 'London' },
];
const timeZone = [
  { value: '1', label: '(+5:30) India Standard Time ( Asia/Kolkata )' },
  { value: '2', label: '(+5:30) India Standard Time ( Asia/Kolkata )' },
  { value: '3', label: '(+5:30) India Standard Time ( Asia/Kolkata )' },
  { value: '4', label: '(+5:30) India Standard Time ( Asia/Kolkata )' },
];

const hasNumber = (str) => {
  return /\d/.test(str);
};

const hasLength = (str) => {
  if (str.length > 7) {
    return true;
  } else {
    return false;
  }
};

const hasLowerCase = (str) => {
  return /[a-z]/.test(str);
};

const hasUpperCase = (str) => {
  return /[A-Z]/.test(str);
};

const hasSpecialCharacter = (str) => {
  const specialCharacterRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return specialCharacterRegex.test(str);
};

export default function ProfileDrawer(props) {
  const { open } = props;
  const [userInfo, setUserInfo] = useState(
    localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).user : {}
  );
  const [formData, setFormData] = useState({});
  const [passWordData, setPassWordData] = useState({});
  const [error, setError] = useState({});
  const [passwordError, setPasswordError] = useState({});
  const [passwordShow, setPasswordShow] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [passWordForm, setPassWordForm] = useState(false);
  const [notificationSettingsForm, setNotificationSettingsForm] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState({});
  const [loader, setLoader] = useState(false);
  const [isImageUploaded, SetIsImageUploaded] = useState(false);
  const [editProfileForm, setEditProfile] = useState(false);
  const [state, setState] = useState({ right: false });
  const [timezoneList, setTimezoneList] = useState(timezones);

  const { state: globalState, dispatch } = useContext(GlobalContext);
  const errorCode = {
    newPassword: {
      0: '',
      1: 'You know the drill: password, please!',
      4: 'Use at least 8 characters including a number, a special character, an uppercase and a lowercase letter for your password',
    },
    newPasswordObj: {
      requiredFlag: true,
      regexPattern: regExpression.password,
    },
    confirmPassword: {
      0: '',
      1: 'You know the drill: password, please!',
      4: 'Please confirm your Password',
      5: "New password doesn't mactch with the current . Try again.",
    },
    confirmPasswordObj: {
      matchValues: true,
      requiredFlag: true,
      regexPattern: regExpression.password,
    },
    currentPassword: {
      0: '',
      1: 'Enter your current password',
    },
    currentPasswordObj: {
      requiredFlag: true,
    },
    firstName: {
      0: '',
      1: 'Enter your first name',
    },
    firstNameObj: {
      requiredFlag: true,
    },
  };

  // const fetchTimezoneList = () => {
  //   globalGetService(`/timezones/`)
  //   .then(response => {
  //     if(response.statusCode == 200){
  //       const timezoneOptions = Object.keys(response.data).map((key, index) => {
  //         return { value: key, label: key };
  //       });
  //       setTimezoneList(timezoneOptions)
  //     }
  //   })
  // }

  const handleChange = (key, val) => {
    setFormData({
      ...formData,
      [key]: val,
    });
    setError({
      ...error,
      [key]: '',
    });
  };
  const imageUpdate = (image) => {
    SetIsImageUploaded(true);
    if (image[0]) {
      setFormData({
        ...formData,
        image_display: URL.createObjectURL(image[0]),
        image: image,
      });
      setEditProfile(false);
    }
  };
  //------------------------------------------------------------------------------------------------------------
  const handleChangePassword = (key, value) => {
    setPassWordData({
      ...passWordData,
      [key]: value,
    });
    setPasswordError({
      ...passwordError,
      [key]: '',
    });
    if (key == 'newPassword') {
      setPasswordValidation({
        ...passwordValidation,
        minCharacter: hasLength(value),
        oneNumber: hasNumber(value),
        oneUpperCase: hasUpperCase(value),
        oneLowerCase: hasLowerCase(value),
        oneSpecialCharacter: hasSpecialCharacter(value),
      });
    }
  };
  const togglePassword = (key) => {
    setPasswordShow({
      ...passwordShow,
      [key]: !passwordShow[key],
    });
  };
  const handleSubmitPassword = () => {
    setLoader(true);
    let validateNewInput = {
      newPassword:
        errorCode['newPassword'][
          fieldValidation({
            ...errorCode['newPasswordObj'],
            fieldval: passWordData.newPassword,
          })
        ],
      confirmPassword:
        errorCode['confirmPassword'][
          fieldValidation({
            ...errorCode['confirmPasswordObj'],
            fieldval: passWordData.confirmPassword,
            fieldvalConfirm: passWordData.newPassword,
          })
        ],
      currentPassword: userInfo.is_social_login
        ? ''
        : errorCode['currentPassword'][
            fieldValidation({
              ...errorCode['currentPasswordObj'],
              fieldval: passWordData.currentPassword,
            })
          ],
    };
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == '')) {
      if (userInfo.is_social_login) {
        const password_data = {
          password: passWordData.newPassword,
        };
        globalPostService(`/set-password/`, password_data).then((response) => {
          setLoader(false);
          if (response.statusCode === 200) {
            toastFlashMessage(response.message, 'success');
            setPassWordForm(false);
            setPassWordData({
              currentPassword: '',
              newPassword: '',
              confirmPassword: '',
            });
            setPasswordError({});
            setPasswordShow({
              currentPassword: false,
              newPassword: false,
              confirmPassword: false,
            });
            let userInfo = JSON.parse(localStorage.getItem('userInfo'));
            userInfo = {
              ...userInfo,
              user: response.data,
            };
            localStorage.setItem('userInfo', JSON.stringify(userInfo));
            dispatch({
              type: 'SET_USER',
              payload: response.data,
            });
          } else {
            toastFlashMessage(response.message, 'error');
          }
        });
      } else {
        const password_data = {
          current_password: passWordData.currentPassword,
          new_password: passWordData.newPassword,
        };
        globalPostService(`/change-password/`, password_data).then((response) => {
          setLoader(false);
          if (response.statusCode === 200) {
            toastFlashMessage(response.message, 'success');
            setPassWordForm(false);
            setPassWordData({
              currentPassword: '',
              newPassword: '',
              confirmPassword: '',
            });
            setPasswordError({});
            setPasswordShow({
              currentPassword: false,
              newPassword: false,
              confirmPassword: false,
            });
          } else {
            toastFlashMessage(response.message, 'error');
          }
        });
      }
    } else {
      setLoader(false);
      setPasswordError(validateNewInput);
    }
  };

  const handleSubmit = () => {
    setLoader(true);
    const validateNewInput = {
      firstName:
        errorCode['firstName'][
          fieldValidation({
            ...errorCode['firstNameObj'],
            fieldval: formData.firstName,
          })
        ],
    };
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == '')) {
      const postData = new FormData();
      if (formData.image_display) {
        for (let file of formData.image) {
          postData.append('picture', file, file.name);
        }
      }
      postData.append('first_name', formData.firstName);
      postData.append('last_name', formData.lastName);
      postData.append('timezone', formData.timezone);
      postData.append('designation', formData.designation);
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      globalPostService('/profile-update/', postData)
        .then((response) => {
          if (response.statusCode === 200) {
            toastFlashMessage(response.message, 'success');
            setLoader(false);
            props.handleClose(false);
            // setFormData(response.data);
            localStorage.setItem('userInfo', JSON.stringify({ ...userInfo, user: response.data }));
            dispatch({
              type: 'SET_USER',
              payload: response.data,
            });
            props.handleClose();
          } else {
            toastFlashMessage(response.message, 'error');
            setLoader(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setLoader(false);
      setError(validateNewInput);
    }
  };

  useEffect(() => {
    setState({ ...state, ['right']: open });
    setUserInfo(
      localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).user : {}
    );
    const userData = localStorage.getItem('userInfo')
      ? JSON.parse(localStorage.getItem('userInfo')).user
      : '';
    setFormData({
      firstName: userData.first_name ? userData.first_name : '',
      lastName: userData.last_name ? userData.last_name : '',
      email: userData.email ? userData.email : '',
      designation: userData.designation ? userData.designation : '',
      // company: userData.company ? userData.company : '',
      image: userData.profile_pic ? userData.profile_pic : '',
      image_display: '',
      timezone: userData.timezone
        ? getTimezoneOptions(timezoneList).find(({ value }) => value === userData.timezone)?.value
        : Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    // console.log(getTimezoneOptions(timezoneList).find(({ value }) => value === userData.timezone));
    setPassWordData({
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
    setError({});
    setPasswordError({});
    setPassWordForm(false);
    setNotificationSettingsForm(false);
    setEditProfile(false);
    setPasswordShow({
      currentPassword: false,
      newPassword: false,
      confirmPassword: false,
    });
    setPasswordValidation({
      minCharacter: false,
      oneNumber: false,
      oneUpperCase: false,
      oneLowerCase: false,
      oneSpecialCharacter: false,
    });
    setLoader(false);
  }, [open]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    props.handleClose();
    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    setState({ ...state, ['right']: open });
  }, [open]);

  const errorType = (val, message) => {
    return (
      <li className="paragraph">
        {val ? (
          <img src={imageBasePath + 'right.svg'} alt="image" />
        ) : (
          <img src={imageBasePath + 'wrong.svg'} alt="image" />
        )}
        <span>{message}</span>
      </li>
    );
  };

  // Notification preferences
  const [notifications, setNotifications] = useState([]);
  const onNotificationsChange = (newNotifications) => setNotifications(newNotifications);

  const handleNotificationsSave = () => {
    const to_update = notifications
      .filter(({ is_optional }) => !!is_optional)
      .map(({ is_enabled, id }) => ({
        notification_id: id,
        is_enabled,
      }));

    updateNotificationPreferences(to_update).then((response) => {
      if (response.statusCode === 200) {
        setNotificationSettingsForm(false);
        toastFlashMessage('Notification preferences updated', 'success');
      }
    });
  };

  // FFs
  const { isEnabled: emailNotifications } = useGetFF({ flag_id: 'email_notification_preferences' });

  return (
    <div>
      {['right'].map((anchor, index) => (
        <div key={index}>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            className="profileDrawer"
          >
            <div className="profileDrawerHeader">
              <img
                src={imageBasePath + 'close.svg'}
                className="profileDrawerCloseIcon"
                onClick={toggleDrawer(anchor, false)}
              />
              <h5 className="profileDrawerHeaderTitle heading5">My Account</h5>
            </div>
            <div className="profileDrawerBody">
              <form>
                {notificationSettingsForm ? (
                  <Grid container className="profileDrawerInputRow">
                    <NotificationSettings
                      onNotificationsChange={onNotificationsChange}
                      onGoBack={() => setNotificationSettingsForm(false)}
                    />
                  </Grid>
                ) : passWordForm ? (
                  <>
                    <Grid container className="profileDrawerInputRow">
                      <Grid item xs={12}>
                        <div className={`passwordFormTitle`}>
                          <ArrowBackIcon
                            onClick={() => {
                              setPassWordForm(false);
                              setPassWordData({
                                currentPassword: '',
                                newPassword: '',
                                confirmPassword: '',
                              });
                              setPasswordError({});
                              setPasswordShow({
                                currentPassword: false,
                                newPassword: false,
                                confirmPassword: false,
                              });
                            }}
                          />
                          <h3 className={`heading5`}>Reset password</h3>
                        </div>
                      </Grid>
                      {userInfo && userInfo.is_social_login ? null : (
                        <Grid item xs={12} className="passwordGroup profileDrawerInputWrapper">
                          <InputText
                            id="currentPassword"
                            label={`Current Password`}
                            //  type={formData.password_show ? 'text' : 'password'}
                            className={`profileDrawerInput ${
                              passwordError.currentPassword ? 'errorGroup' : ''
                            }`}
                            placeholder="Type in your current password"
                            type={passwordShow.currentPassword ? 'text' : 'password'}
                            onChange={(e) =>
                              handleChangePassword('currentPassword', e.target.value)
                            }
                            value={passWordData.currentPassword}
                          />

                          <span
                            className="passwordShow metaText"
                            onClick={() => togglePassword('currentPassword')}
                          >
                            {passwordShow.currentPassword ? (
                              <img
                                src={imageBasePath + 'eye_hide.svg'}
                                className="imgHide"
                                alt="Hide"
                              />
                            ) : (
                              <img src={imageBasePath + 'eye_show.svg'} alt="Show" />
                            )}
                          </span>
                          <h6 className="errorMsg">{passwordError.currentPassword}</h6>
                        </Grid>
                      )}
                      <Grid item xs={12} className="passwordGroup profileDrawerInputWrapper">
                        <Tooltip
                          title={
                            <div className={'tooltipWrapper'}>
                              <p className="supportText">Password must contain</p>
                              <ul className="listUnstyled">
                                {errorType(
                                  passwordValidation.minCharacter,
                                  'At least 8 characters'
                                )}
                                {errorType(
                                  passwordValidation.oneSpecialCharacter,
                                  'A Special character'
                                )}
                                {errorType(passwordValidation.oneNumber, 'A number (0-9)')}
                                {errorType(
                                  passwordValidation.oneLowerCase,
                                  'A Lowercase letter (a-z)'
                                )}
                                {errorType(
                                  passwordValidation.oneUpperCase,
                                  'A Uppercase letter (A-Z)'
                                )}
                              </ul>
                            </div>
                          }
                          placement="top"
                          PopperProps={{
                            disablePortal: true,
                          }}
                          disableHoverListener
                        >
                          <InputText
                            id="newPassword"
                            label={`New Password`}
                            placeholder="New password"
                            className={`profileDrawerInput ${
                              passwordError.newPassword ? 'errorGroup' : ''
                            }`}
                            type={passwordShow.newPassword ? 'text' : 'password'}
                            onChange={(e) => handleChangePassword('newPassword', e.target.value)}
                            value={passWordData.newPassword}
                          />
                        </Tooltip>
                        <span
                          className="passwordShow metaText"
                          onClick={() => togglePassword('newPassword')}
                        >
                          {passwordShow.newPassword ? (
                            <img
                              src={imageBasePath + 'eye_hide.svg'}
                              className="imgHide"
                              alt="Hide"
                            />
                          ) : (
                            <img src={imageBasePath + 'eye_show.svg'} alt="Show" />
                          )}
                        </span>
                        <h6 className={passwordError.confirmPassword ? 'errorMsg' : ''}>
                          {passwordError.newPassword ? passwordError.newPassword : ''}
                        </h6>
                      </Grid>
                      <Grid item xs={12} className="passwordGroup profileDrawerInputWrapper">
                        <InputText
                          id="confirmPassword"
                          label={`Confirm New Password`}
                          placeholder="Confirm password"
                          className={`profileDrawerInput ${
                            passwordError.confirmPassword ? 'errorGroup' : ''
                          }`}
                          type={passwordShow.confirmPassword ? 'text' : 'password'}
                          onChange={(e) => handleChangePassword('confirmPassword', e.target.value)}
                          value={passWordData.confirmPassword}
                        />
                        <span
                          className="passwordShow metaText"
                          onClick={() => togglePassword('confirmPassword')}
                        >
                          {passwordShow.confirmPassword ? (
                            <img
                              src={imageBasePath + 'eye_hide.svg'}
                              className="imgHide"
                              alt="Hide"
                            />
                          ) : (
                            <img src={imageBasePath + 'eye_show.svg'} alt="Show" />
                          )}
                        </span>
                        <h6 className={passwordError.confirmPassword ? 'errorMsg' : ''}>
                          {passwordError.confirmPassword ? passwordError.confirmPassword : ''}
                        </h6>
                      </Grid>
                      {/* <Grid item xs={12} className={'submitBlk'}>
                                                {<List className='listInline'>
                                                    <ListItem className='listInlineItem'>
                                                        <CustomButton
                                                            linkText='Cancel'
                                                            className="tertiaryBtn"
                                                            onClick={() => {
                                                              setPassWordForm(false);
                                                              setPassWordData({
                                                                currentPassword: '',
                                                                newPassword: '',
                                                                confirmPassword: '',
                                                              });
                                                            }}
                                                        >
                                                            Cancel
                                                        </CustomButton>
                                                    </ListItem>
                                                    <ListItem className='listInlineItem'>
                                                        <CustomButton
                                                            type='submit'
                                                            className={
                                                            false
                                                                ? 'primaryBtn disabledBtn'
                                                                : 'primaryBtn '
                                                            }
                                                            onClick={handleSubmitPassword}
                                                        >
                                                            Save
                                                        </CustomButton>
                                                    </ListItem>
                                                </List>}
                                                          </Grid>*/}
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid container className="profileImageWrapper" spacing={2}>
                      <Grid item xs={12} className="profileImage">
                        {formData.image_display ? (
                          <img className="profileDrawerImg" src={formData.image_display} alt="" />
                        ) : formData.image ? (
                          <img src={formData.image} className="profileDrawerImg" />
                        ) : (
                          <Avatar className="profileImageAvatar avatar">
                            {userInfo.first_name ? userInfo.first_name[0] : userInfo.email[0]}
                          </Avatar>
                        )}
                        {/* {editProfileForm ? <List className='listInline editFormList'>
                                                    <ListItem className='listInlineItem'>
                                                      <p className={'inputGroup inputFileGroup'}>
                                                        <input
                                                          type='file'
                                                          accept='.png, .jpg, .jpeg'
                                                          id='upload-pic'
                                                          onChange={(e) => imageUpdate(e.target.files)}
                                                          className='hidden'
                                                        />
                                                        <label htmlFor='upload-pic' >
                                                          <a className=' paragraph'>
                                                            <span>Upload Photo</span>
                                                          </a>
                                                        </label>
                                                      </p>
                                                    </ListItem>
                                                    <ListItem className='listInlineItem'>
                                                      <p className={'inputGroup inputFileGroup'}>
                                                        <a className=' paragraph'>
                                                          <span
                                                            onClick={() => {
                                                              handleRemovePic();
                                                              setEditProfile(false);
                                                            }}
                                                          >
                                                            Remove photo
                                                          </span>
                                                        </a>
                                                      </p>
                                                    </ListItem>
                                                </List> : <div>
                                                  <CustomButton
                                                      className="profileEditBtn supportText"
                                                      children="Edit Profile Photo"
                                                      onClick={() => setEditProfile(true)}
                                                  />
                                                </div>} */}
                        <List className="listInline editFormList">
                          <ListItem className="listInlineItem">
                            <p className={'inputGroup inputFileGroup'}>
                              <input
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                id="upload-pic"
                                onChange={(e) => imageUpdate(e.target.files)}
                                className="hidden"
                              />
                              <label htmlFor="upload-pic">
                                <a className=" paragraph">
                                  <span>Edit Profile Photo</span>
                                </a>
                              </label>
                            </p>
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                    <Grid container className="profileDrawerInputRow" spacing={2}>
                      <Grid item xs={6} className="profileDrawerInputWrapper">
                        <InputText
                          id="firstName"
                          label={`First Name`}
                          placeholder="eg. John"
                          className={`profileDrawerInput ${error.firstName ? 'errorGroup' : ''}`}
                          value={formData.firstName}
                          onChange={(e) => handleChange('firstName', e.target.value)}
                        />
                        {error.firstName ? <h6 className="errorMsg">{error.firstName}</h6> : null}
                      </Grid>
                      <Grid item xs={6} className="profileDrawerInputWrapper">
                        <InputText
                          id="lastName"
                          label={`Last Name`}
                          placeholder="eg. Doe"
                          className={`profileDrawerInput ${error.lastName ? 'errorGroup' : ''}`}
                          value={formData.lastName}
                          onChange={(e) => handleChange('lastName', e.target.value)}
                        />
                      </Grid>
                    </Grid>
                    <Grid container className="profileDrawerInputRow" spacing={2}>
                      <Grid item xs={12} className="profileDrawerInputWrapper">
                        <InputText
                          id="email"
                          label={`Email Address`}
                          placeholder="johndoe@gmail.com"
                          className="profileDrawerInput disabledInputGroup"
                          value={formData.email}
                        />
                      </Grid>
                      <Grid item xs={12} className="profileDrawerInputWrapper">
                        <InputText
                          id="designation"
                          label={`Designation`}
                          placeholder="eg. CEO"
                          className="profileDrawerInput"
                          value={formData.designation}
                          onChange={(e) => handleChange('designation', e.target.value)}
                        />
                        {error.designation ? <h6 className="errorMsg">{error.firstName}</h6> : null}
                      </Grid>
                      <Grid item xs={12} className="profileDrawerInputWrapper">
                        <InputSelect
                          className={`reactSelect ${error.timezone ? 'errorGroup' : ''}`}
                          closeMenuOnSelect={true}
                          label={`Time Zone`}
                          options={getTimezoneOptions(timezoneList)}
                          // defaultValue={{
                          //   label: timezoneList.find(tz => tz.key === formData.timezone)?.label,
                          //   value: formData.timezone,
                          // }}
                          isSearchable={true}
                          placeholder={'Select Time Zone'}
                          noOptionsMessage={() => 'No matches found'}
                          // menuPlacement={"top"}
                          // value={formData.timezone}
                          onChange={(selectedOption) => {
                            handleChange('timezone', selectedOption ? selectedOption.value : '');
                          }}
                          value={getTimezoneOptions(timezoneList).find(
                            ({ value }) => value === formData.timezone
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} className="profileDrawerInputWrapper">
                        <div
                          className="changePasswordWrapper"
                          onClick={() => {
                            setPassWordForm(true);
                          }}
                        >
                          <p className="supportText">Reset Password</p>
                          <img
                            src={imageBasePath + 'arrow_next.svg'}
                            className="passwordExpandIcon"
                          />
                        </div>
                      </Grid>
                      {emailNotifications && (
                        <Grid item xs={12} className="profileDrawerInputWrapper">
                          <div
                            className="changePasswordWrapper"
                            onClick={() => {
                              setNotificationSettingsForm(true);
                            }}
                          >
                            <p className="supportText">Notification Settings</p>
                            <img
                              src={imageBasePath + 'arrow_next.svg'}
                              className="passwordExpandIcon"
                            />
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  </>
                )}
              </form>
            </div>
            <div className="profileFooter">
              <Grid container direction="row" spacing={3} className="profileSubmitBtnRow">
                <Grid item xs={12} className="profileSubmitBtnWrapper">
                  {passWordForm && (
                    <CustomButton
                      className={`primaryBtn submitBtn ${
                        loader || Object.keys(passwordError).some((k) => passwordError[k] != '')
                          ? `disabledBtn`
                          : ''
                      }`}
                      children="Save"
                      onClick={handleSubmitPassword}
                    />
                  )}

                  {notificationSettingsForm && (
                    <CustomButton
                      className={`primaryBtn submitBtn ${loader ? `disabledBtn` : ''}`}
                      children="Update notifications"
                      onClick={handleNotificationsSave}
                    />
                  )}

                  {!passWordForm && !notificationSettingsForm && (
                    <CustomButton
                      className={`primaryBtn submitBtn ${
                        loader || Object.keys(error).some((k) => error[k] != '')
                          ? `disabledBtn`
                          : ''
                      }`}
                      children="Update"
                      onClick={handleSubmit}
                    />
                  )}
                </Grid>
              </Grid>
            </div>
          </Drawer>
        </div>
      ))}
    </div>
  );
}
