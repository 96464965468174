import { useState } from 'react';

function useHandleSeconds() {
  const [listSeconds, setListSeconds] = useState(
    JSON.parse(localStorage.getItem('contentsSeconds')) || []
  );
  const setSeconds = (uuid_, seconds_) => {
    const step = listSeconds?.find((item) => item?.uuid === uuid_);
    if (step) step.seconds = seconds_;
    else setListSeconds([...listSeconds, { uuid: uuid_, seconds: seconds_ }]);
    localStorage.setItem('contentsSeconds', JSON.stringify(listSeconds));
  };

  const getSeconds = (uuid) => {
    const step = listSeconds?.find((item) => item?.uuid === uuid);
    if (step) {
      return step?.seconds;
    }
    return 1;
  };

  return { setSeconds, getSeconds };
}

export { useHandleSeconds };
