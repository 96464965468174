import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import QueryWrapper from './hocs/QueryWrapper';
import Maintenance from 'hocs/Maintenance';
import { FeatureFlagsProvider } from '@fstack-tech/ff_react_module';

const env_id = process.env.REACT_APP_FF_ENV;
const json_path = 'src/flags.json';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  // <React.StrictMode>
  <FeatureFlagsProvider env_id={env_id} json_path={json_path}>
    <DndProvider backend={HTML5Backend}>
      <QueryWrapper>
        <Maintenance>
          <App />
        </Maintenance>
      </QueryWrapper>
    </DndProvider>
  </FeatureFlagsProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
