import React, { useState, useEffect, useMemo } from 'react';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import { CustomButton } from 'shared_ui_components';
import styles from './SubmitReview.module.scss';
import { useSubmitReview } from 'features/roleplay/Roleplay.queries';
import { useLocation, useHistory } from 'react-router-dom';
import SubmitReviewCard from './SubmitReviewCard';

const SubmitReviewContent = () => {
  const roleplayView = useRoleplayStore((state) => state?.roleplayView);
  const [scenarios, setScenarios] = useState([]);
  const { mutate: submitReview } = useSubmitReview();
  let location = useLocation();
  let history = useHistory();
  useEffect(() => {
    setScenarios(roleplayView?.scenarios || []);
  }, [roleplayView]);

  const handleSubmit = () => {
    submitReview(
      { roleplay_id: roleplayView.uuid },
      {
        onSuccess: () => {
          history.push({
            pathname: `/roleplay`,
            state: {
              prevPath: `${location.pathname}${location.search ? location.search : ''}`,
            },
          });
        },
      }
    );
  };

  const disabled = useMemo(
    () => scenarios.every((item) => item.response.status && item.response.average),
    [scenarios]
  );

  return (
    <div>
      {scenarios.map((scenario, index) => {
        return (
          <SubmitReviewCard
            title={scenario.is_default === false ? scenario.name : roleplayView?.name}
            description={
              scenario.description ? scenario.description : roleplayView?.role_play.description
            }
            index={index + 1}
            qualified={scenario?.response_retake !== null ? scenario?.response_retake.status: scenario.response.status}
            key={index}
            stars={scenario?.response_retake !== null ? scenario.response_retake.average : scenario.response.average}
          />
        );
      })}
      <div className={styles.submitButton}>
        <CustomButton onClick={handleSubmit} disabled={!disabled}>
          Submit
        </CustomButton>
      </div>
    </div>
  );
};

export default SubmitReviewContent;
