import { useState, useEffect } from 'react';
import Rating from '@material-ui/lab/Rating';
import { List, ListItem, Grid, Avatar } from '@material-ui/core';
import {
  getOnboardingAverageTimeTakenApi,
  getOnboardingTeamProgressApi,
  getOnboardingProgressRatingApi,
  getOnboardingRatingV2,
  getProgress,
  downloadReport,
  getOnboardingRealTime,
  getOnboardingTeamProgressV2,
} from '../apiServices';
import { imageBasePath } from '../../../constants';
import { downloadFileType, TooltipCustom } from '../../../utils';
import { CustomTooltipWhite, ProgressBar, NoResult, Nudge } from '../../../shared_ui_components';
import { OverviewHero } from '../../../shared_elements';
import { CustomButton } from '../../../shared_ui_components';
import { format, parseISO } from 'date-fns';
import styles from '../../../assets/styles/onboarding.module.scss';
import stylesTrackin from '../../../assets/styles/tracking.module.scss';
import Shimmer from './Shimmer';
import { FeatureFlag } from 'controllers/_exports';
import DateTime from 'infrastructure/Datetime';
import Info_red from 'assets/icons/Important_red.svg';
import Filter from 'features/home/components/Filters/Filter';
import { useOnboardingStore } from '../Onboarding.store';
import style from './Onboarding.module.scss';
import Organization from 'infrastructure/Organization';
import { useGetFF } from 'hooks/useGetFF';

export default function OnboardingDashboard(props) {
  const [teamProgress, setTeamProgress] = useState([]);
  const [averageTimeTaken, setAverageTimeTaken] = useState('');
  const [progressRating, setProgressRating] = useState(null);
  const [ratings, setRatings] = useState(null);
  const [progress, setProgress] = useState(null);
  const [loader, setLoader] = useState({
    progressRating: false,
    averageTimeTaken: false,
    teamProgress: true,
    searchUserLoader: false,
  });
  const [searchVal, setSearhVal] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [downloadReportLoader, downloadReportLoaderSet] = useState(false);

  const [pagination, setPagination] = useState([]);
  const [count, setCount] = useState(1);
  const [viewLoader, setViewLoader] = useState(false);
  const [realTime, setRealTime] = useState('');
  const { selectedDateFilterOnboarding } = useOnboardingStore();

  const { isEnabled: ff_newOnboardingMetrics } = useGetFF({ flag_id: 'new_onboarding_metrics' });

  const fetchTeamProgress = (query = {}) => {
    setViewLoader(true);
    getOnboardingTeamProgressApi({
      query,
      time: Organization.FiltersEnabled(selectedDateFilterOnboarding.slug),
    })
      .then((response) => {
        if (response.statusCode === 200) {
          setLoader((prevState) => ({
            ...prevState,
            teamProgress: false,
            searchUserLoader: false,
          }));

          if (response?.data?.list) {
            if (response?.data?.pagination?.current_page > 1)
              setTeamProgress([...teamProgress, ...response?.data?.list]);
            else setTeamProgress([...response?.data?.list]);
          } else {
            setTeamProgress([]);
          }

          setPagination(response?.data?.pagination || []);
          setCount(response?.data?.pagination?.current_page || 1);
          setRealTime(response?.data?.real_time || realTime);
        }
      })
      .finally(() => {
        setViewLoader(false);
      });
  };
  const fetchTeamProgressV2 = (query = {}) => {
    getOnboardingTeamProgressV2({
      query,
      time: Organization.FiltersEnabled(selectedDateFilterOnboarding.slug),
    })
      .then((response) => {
        if (response.statusCode === 200) {
          setLoader((prevState) => ({
            ...prevState,
            teamProgress: false,
            searchUserLoader: false,
          }));

          if (response?.data?.list) {
            if (response?.data?.pagination?.current_page > 1)
              setTeamProgress([...teamProgress, ...response?.data?.list]);
            else setTeamProgress([...response?.data?.list]);
          } else {
            setTeamProgress([]);
          }

          setPagination(response?.data?.pagination || []);
          setCount(response?.data?.pagination?.current_page || 1);
        }
      })
      .finally(() => {
        setViewLoader(false);
      });

    getOnboardingRealTime()
      .then((response) => {
        if (response.statusCode === 200) {
          setLoader((prevState) => ({
            ...prevState,
            teamProgress: false,
            searchUserLoader: false,
          }));
          setRealTime(response?.data?.real_time || realTime);
        }
      })
      .finally(() => {
        setViewLoader(false);
      });
  };
  const handleViewMore = () => {
    // debugger
    setSearhVal('');
    setSearchResults([]);
    if (count <= pagination.number_of_pages) {
      setCount((prevCount) => prevCount + 1);
      if (!ff_newOnboardingMetrics) fetchTeamProgress({ page: count + 1 });
      else fetchTeamProgressV2({ page: count + 1 });
    }
  };

  useEffect(() => {
    getOnboardingAverageTimeTakenApi(
      {
        time_lapse: Organization.FiltersEnabled(selectedDateFilterOnboarding.slug),
      },
      ff_newOnboardingMetrics
    ).then((response) => {
      if (response.statusCode === 200) {
        setAverageTimeTaken(ff_newOnboardingMetrics ? response?.data?.average_time : response.data);
      }
    });
  }, [selectedDateFilterOnboarding]);

  useEffect(() => {
    setSearhVal('');
    if (!ff_newOnboardingMetrics) fetchTeamProgress({ page: 1 });
    else fetchTeamProgressV2({ page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateFilterOnboarding]);

  useEffect(() => {
    setLoader((prevState) => ({
      ...prevState,
      progressRating: true,
    }));
    ff_newOnboardingMetrics &&
      getOnboardingRatingV2({
        time: Organization.FiltersEnabled(selectedDateFilterOnboarding.slug),
      }).then((response) => {
        if (response.statusCode === 200) {
          setLoader((prevState) => ({
            ...prevState,
            progressRating: false,
          }));
          setProgressRating({ rating: response.data.ratings });
          setRatings(response.data.ratings);
        } else {
          setLoader((prevLoader) => ({
            ...prevLoader,
            overall: false,
          }));
        }
      });
    ff_newOnboardingMetrics &&
      getProgress({
        time_lapse: Organization.FiltersEnabled(selectedDateFilterOnboarding.slug),
      }).then((response) => {
        if (response.statusCode === 200) {
          setLoader((prevState) => ({
            ...prevState,
            progressRating: false,
          }));
          setProgress(response.data.progress);
        } else {
          setLoader((prevLoader) => ({
            ...prevLoader,
            overall: false,
          }));
        }
      });
    !ff_newOnboardingMetrics &&
      getOnboardingProgressRatingApi({
        time: Organization.FiltersEnabled(selectedDateFilterOnboarding.slug),
      }).then((response) => {
        if (response.statusCode === 200) {
          setLoader((prevState) => ({
            ...prevState,
            progressRating: false,
          }));
          setProgressRating(response.data);
          setProgress(response.data.progress);
          setRatings(response.data.ratings);
        } else {
          setLoader((prevLoader) => ({
            ...prevLoader,
            overall: false,
          }));
        }
      });
  }, [selectedDateFilterOnboarding]);
  const handleSearchUser = (value) => {
    setLoader((prevState) => ({
      ...prevState,
      searchUserLoader: true,
    }));
    setSearhVal(value);
    let results = teamProgress.filter(
      (user) =>
        user.first_name?.includes(value) ||
        user.last_name?.includes(value) ||
        user.email?.includes(value)
    );
    if (results && results.length) {
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };
  const downloadDashboard = () => {
    downloadReportLoaderSet(true);
    downloadReport()
      .then((response) => {
        downloadReportLoaderSet(false);
        if (response) {
          downloadFileType(response, 'Onboardng Dashboard', '.pdf');
        } else {
          console.log(response, 'err');
        }
      })
      .catch((err) => {
        console.error(err, 'err');
      });
  };
  return (
    <>
      <section className="filterSection">
        <Grid container direction="row" spacing={2} justifyContent="flex-end">
          <Grid item lg={6} md={6} sm={8} xs={12}>
            <div className="filterBlocker flexCentered">
              <List className="listInline filterList hidden">
                <ListItem className={`listInlineItem supportText `}>Daily</ListItem>
                <ListItem className={`listInlineItem supportText `}>Weekly</ListItem>
                <ListItem className={`listInlineItem supportText active`}>Monthly</ListItem>
                <ListItem className={`listInlineItem supportText `}>Quarterly</ListItem>
              </List>
              <div style={{ display: 'flex', maxHeight: '3rem', gap: '1rem' }}>
                <button
                  className={`paragraph downloadBtn ${downloadReportLoader ? 'disabledBtn' : ''}`}
                  onClick={() => downloadDashboard()}
                >
                  <img src={imageBasePath + 'download.svg'} alt="download icon" />
                  {downloadReportLoader ? 'Downloading...' : 'Download Report'}
                </button>
                {FeatureFlag.enabled('FILTERS') && (
                  <div className={style.filter}>
                    <span>Period</span>
                    <Filter page="onboarding" />
                  </div>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
      {loader?.progressRating || loader?.averageTimeTaken ? (
        'Loading...'
      ) : (
        <OverviewHero
          chartData={{
            title: 'Progress',
            totalText: 'Total onboarded users',
            progress: progress,
          }}
          progressStatus={{
            title: averageTimeTaken,
            description: 'Average time to complete the onboarding',
            image: imageBasePath + 'averge_time_left.svg',
          }}
          overallRating={{
            title: 'Overall Ratings',
            ratingInfo: {
              averageRating: ratings?.total_rating,
              rating1: ratings?.rating_1,
              rating1Users: ratings?.rating_1_users,
              rating1UsersLeft: ratings?.rating_1_users_left,
              rating1Percentage: ratings?.rating_1_percentage,
              rating2: ratings?.rating_2,
              rating2Users: ratings?.rating_2_users,
              rating2UsersLeft: ratings?.rating_2_users_left,
              rating2Percentage: ratings?.rating_2_percentage,
              rating3: ratings?.rating_3,
              rating3Users: ratings?.rating_3_users,
              rating3UsersLeft: ratings?.rating_3_users_left,
              rating3Percentage: ratings?.rating_3_percentage,
              rating4: ratings?.rating_4,
              rating4Users: ratings?.rating_4_users,
              rating4UsersLeft: ratings?.rating_4_users_left,
              rating4Percentage: ratings?.rating_4_percentage,
              rating5: ratings?.rating_5,
              rating5Users: ratings?.rating_5_users,
              rating5UsersLeft: ratings?.rating_5_users_left,
              rating5Percentage: ratings?.rating_5_percentage,
              totalReviews: ratings?.total_reviews,
            },
          }}
        />
      )}
      {/* <Shimmer type="OnboardingOverview" /> */}
      <section className={' dashboardListing'}>
        <Grid container direction="row">
          <Grid item md={12}>
            <div className="teamProgressWrapper">
              <Grid container direction="row" spacing={4} className="headingRow">
                <Grid item md={5}>
                  <h3 className="heading5">Team Progress</h3>
                </Grid>
                <Grid item md={7}>
                  <ul className={`listInline filtersList`}>
                    <li className="listInlineItem inputGroup">
                      <input
                        type="text"
                        value={searchVal}
                        onChange={(e) => handleSearchUser(e.target.value)}
                        onBlur={(e) => setSearhVal(e.target.value.trim())}
                        className="search"
                        placeholder="Search for a user"
                      />
                      {searchVal ? (
                        <img
                          src={imageBasePath + 'close_gray.svg'}
                          alt="close icon"
                          onClick={() => {
                            handleSearchUser('');
                            setSearhVal('');
                          }}
                          className="cancelBtn"
                        />
                      ) : null}
                    </li>
                    <li className="listInlineItem hidden">
                      <button className="supportText">
                        Filter
                        <svg
                          width="16"
                          height="10"
                          viewBox="0 0 16 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.33333 0C0.873095 0 0.5 0.373096 0.5 0.833333C0.5 1.29357 0.873096 1.66667 1.33333 1.66667H14.6667C15.1269 1.66667 15.5 1.29357 15.5 0.833333C15.5 0.373096 15.1269 0 14.6667 0H1.33333ZM6.33374 9.16646C6.33374 9.6267 6.70684 9.9998 7.16707 9.9998H8.83374C9.29398 9.9998 9.66707 9.6267 9.66707 9.16646C9.66707 8.70623 9.29398 8.33313 8.83374 8.33313H7.16707C6.70684 8.33313 6.33374 8.70623 6.33374 9.16646ZM3.83364 5.83415C3.3734 5.83415 3.00031 5.46105 3.00031 5.00081C3.00031 4.54058 3.3734 4.16748 3.83364 4.16748H12.167C12.6272 4.16748 13.0003 4.54058 13.0003 5.00081C13.0003 5.46105 12.6272 5.83415 12.167 5.83415H3.83364Z"
                            fill="#BFD4DF"
                          />
                        </svg>
                      </button>
                    </li>
                  </ul>
                </Grid>
              </Grid>
              <div className="teamProgressDetail">
                <Grid container direction="row" spacing={4} className="titleRow">
                  <Grid item md={FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') ? 4 : 3}>
                    <h6 className="supportTextSemibold">User</h6>
                  </Grid>
                  <Grid item md={2}>
                    <h6 className="supportTextSemibold">Started On</h6>
                  </Grid>
                  <Grid item md={2}>
                    <h6 className="supportTextSemibold">Status</h6>
                  </Grid>
                  {FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') && (
                    <Grid item md={2} style={{ display: 'flex', gap: '0.5rem' }}>
                      <CustomTooltipWhite
                        tooltipIcon="info_icon.svg"
                        title={
                          <p className={stylesTrackin.checkinTooltipContent + ' heading6 '}>
                            This Field Shows The Actual Vs Planned Time Of Completion for mandatory
                            content
                          </p>
                        }
                        className={stylesTrackin.checkinTooltip}
                        placement="top"
                      />
                      <h6 className="supportTextSemibold">
                        Duration ({DateTime.formatSecondsToHHMM(realTime)})
                      </h6>
                    </Grid>
                  )}
                  <Grid item md={FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') ? 2 : 3}>
                    <h6 className="supportTextSemibold">Feedback</h6>
                  </Grid>
                </Grid>
                <div className="contentBlock">
                  {loader?.teamProgress ? (
                    <Shimmer type="OnboardingOverviewUsers" />
                  ) : (searchVal && searchResults && searchResults.length) ||
                    (!searchVal && teamProgress && teamProgress.length) ? (
                    <>
                      {(searchResults && searchResults.length) || searchVal
                        ? searchResults.map((user) => <TeamMember key={user.uuid} user={user} />)
                        : teamProgress.map((user) => <TeamMember key={user.uuid} user={user} />)}
                      {!searchVal && pagination.total && pagination.total > 10 ? (
                        <div className={styles.viewMoreBtn}>
                          <p
                            className={
                              pagination.total == teamProgress.length ? 'hidden' : 'textCenter'
                            }
                            style={{ margin: '24px 0 8px' }}
                          >
                            <CustomButton className="secondaryBtn" onClick={handleViewMore}>
                              {viewLoader ? 'Loading...' : 'View More'}
                            </CustomButton>
                          </p>
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  ) : !searchVal && loader?.searchUserLoader ? (
                    'Loading...'
                  ) : (
                    <NoResult
                      image={`no_user_found.svg`}
                      title={
                        searchVal
                          ? `Sorry, We could not find any user with this name`
                          : `No user available`
                      }
                      subtext={searchVal ? `Please try with some other user name` : ``}
                    />
                  )}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
}
function TeamMember(props) {
  const { user } = props;
  const [openAlert, setOpenAlert] = useState(false);

  const copyEmail = () => {
    var copyText = document.getElementById(`userEmail${user.uuid}`);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
    setOpenAlert(true);
    // setTimeout(() => {
    //     setOpenAlert(false)
    // }, 1000);
  };

  const getDate = (date) => {
    try {
      return format(parseISO(date), 'MM/dd/yyyy');
    } catch (error) {
      return '';
    }
  };

  return (
    <div className="item">
      <Grid container direction="row" spacing={4} alignItems="center">
        <Grid item md={FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') ? 4 : 3}>
          <div className="userDetail flexCentered" style={{ minHeight: '34px' }}>
            {user.profile_pic ? (
              <Avatar className="imageBlk avatar" src={user.profile_pic}>
                H
              </Avatar>
            ) : (
              <Avatar className="imageBlk avatar">
                {user.first_name ? user.first_name.substring(0, 1) : user.email.substring(0, 1)}
              </Avatar>
            )}

            <h5
              className="heading6"
              style={{
                display: 'flex',
                gap: 15,
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              {`${user.first_name || '\u00A0'} ${user.last_name || '\u00A0'}`}
              <Nudge user={user} resource="onboarding" />
            </h5>

            <p className="supportText">
              {`${user.email}`}
              <TooltipCustom
                onClose={() =>
                  setTimeout(() => {
                    setOpenAlert(false);
                  }, 300)
                }
                arrow
                title={
                  <div className="supportText" style={{ color: `#fff` }}>
                    {openAlert ? `Email copied to clipboard` : 'Copy email'}
                  </div>
                }
                {...props}
                placement="top"
              >
                <img
                  className="copyText"
                  onClick={() => copyEmail()}
                  src={imageBasePath + 'copy_icon.svg'}
                  alt="copy"
                />
              </TooltipCustom>
            </p>
            <input
              type="text"
              value={user.email}
              id={`userEmail${user.uuid}`}
              style={{ opacity: 0, position: 'absolute', zIndex: '-100' }}
            />
          </div>
        </Grid>
        <Grid item md={2}>
          {FeatureFlag.enabled('STARTED_ON_ONBOARDING') ? (
            <p className="supportText">
              {user?.started_on ? getDate(user?.started_on) : 'Not yet started'}
            </p>
          ) : (
            <p className="supportText">{user?.activated_at ? getDate(user?.activated_at) : '--'}</p>
          )}
        </Grid>
        <Grid item md={2}>
          {user.status?.value == 2 ? (
            <div className={`progressBlk`}>
              <ProgressBar
                value={user.status?.progress}
                max={100}
                className="stepProgress status2"
              />
            </div>
          ) : (
            <p className="supportText">
              <span className={`status status${user.status?.value}`}>{user.status?.label}</span>
            </p>
          )}
        </Grid>
        {FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') && (
          <Grid item md={2} style={{ display: 'flex' }}>
            {FeatureFlag.enabled('TIME_TRACK_HIGHLIGHT') &&
              user.highlight &&
              user.time_taken_seconds > 0 && (
                <img src={Info_red} alt="info" style={{ marginRight: '0.5rem' }} />
              )}
            <p className="supportText">
              {DateTime.formatSecondsToHHMM(user.time_taken_seconds, 'Not yet completed')}
            </p>
          </Grid>
        )}

        <Grid item md={FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') ? 2 : 3}>
          {user.feedback && user.feedback?.rating ? (
            <>
              <Rating name="read-only" precision={0.1} value={user.feedback?.rating} readOnly />
              {user.feedback?.comment ? (
                <div className="comments">
                  <CustomTooltipWhite
                    placement="top"
                    tooltipIcon={'comment_icon.svg'}
                    title={
                      <div className="customTooltipContent">
                        <p className="paragraph">{user.feedback?.comment}</p>
                      </div>
                    }
                  />
                </div>
              ) : null}
            </>
          ) : (
            <p className="supportText">--</p>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
