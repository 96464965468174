import { useState, useEffect } from 'react';
import { imageBasePath } from '../../../constants';
import { OnboardingLibrary, Shimmer } from './';
import {
  getOnboardingSteps,
  markAsCompleteApi,
  submitOnboardingFeedbackApi,
  onboardinglikeDislikeApi,
  markAsReadApi,
} from '../apiServices';
import styles from '../../../assets/styles/onboarding.module.scss';
import { DashboardHeader } from '../../../shared_elements';
import { toastFlashMessage } from '../../../utils';
import { FeedbackDialog } from '.';
import { NoResult } from '../../../shared_ui_components';
import { useHandleSeconds } from './useHandleSeconds';

export default function UserOnboardingContainer(props) {
  const [loader, setLoader] = useState(true);
  const [state, setState] = useState({
    steps: [],
    activeStep: {},
  });
  const [openFeedback, setOpenFeedback] = useState(false);
  const [feedbackSubmitLoader, setFeedbackSubmittingLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [time, setTime] = useState(0);
  const { steps, activeStep } = state;
  const { setSeconds } = useHandleSeconds();

  const fetchOnboardingSteps = (activeStep = '') => {
    if (activeStep) {
      setState((prevState) => ({
        ...prevState,
        activeStep: activeStep,
      }));
    }
    getOnboardingSteps().then((response) => {
      if (response.statusCode === 200) {
        if (!activeStep) {
          setState((prevState) => ({
            ...prevState,
            activeStep:
              response.data?.onboarding.steps && response.data?.onboarding?.steps?.length
                ? response.data?.onboarding.steps[0]
                : {},
          }));
        }
        let onboardingSteps = response.data?.onboarding?.steps
          ? response.data?.onboarding?.steps
          : [];
        setState((prevState) => ({
          ...prevState,
          steps: onboardingSteps,
        }));
        const mandatorySteps = onboardingSteps.filter((step) => step.is_required);
        const isCompleted =
          mandatorySteps.length === 0
            ? onboardingSteps.every((step) => step.completed_at)
            : mandatorySteps.every((step) => step.completed_at);
        if (isCompleted && !response.data?.rating_given && onboardingSteps.length) {
          setOpenFeedback(true);
        }
        setLoader(false);
      }
    });
  };
  const handleMarkAsComplete = (contentType) => {
    let auxData = {};
    if (contentType === 1 && time === 0) auxData = { time_duration: 1 };
    else auxData = { time_duration: time };
    markAsCompleteApi(activeStep.uuid, auxData).then((response) => {
      if (response.statusCode == 200) {
        toastFlashMessage(response.message, 'success');
        fetchOnboardingSteps(response.data);
        setShowModal(false);
      } else {
        toastFlashMessage(response.message, 'error');
        setShowModal(false);
      }
    });
  };
  const handleChangeStep = (step) => {
    setState({
      ...state,
      activeStep: step,
    });
  };
  const handleSubmitFeedback = (formData) => {
    setFeedbackSubmittingLoader(true);
    submitOnboardingFeedbackApi(formData).then((response) => {
      setFeedbackSubmittingLoader(false);
      if (response.statusCode == 200) {
        toastFlashMessage(response.message, 'success');
        setOpenFeedback(false);
        // if(steps.filter(step => step.))
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };
  useEffect(() => {
    fetchOnboardingSteps();
  }, []);

  const updateActiveStep = (key, val) => {
    switch (key) {
      case 'comments':
        setState({
          ...state,
          activeStep: {
            ...state.activeStep,
            comments: val,
          },
          steps: state.steps.map((step) =>
            step.uuid === activeStep.uuid ? { ...state.activeStep, comments: val } : step
          ),
        });
        return true;
      case 'likeDislike':
        let activeStepUpdated = {
          ...state.activeStep,
          comments: state.activeStep.comments.map((comment) =>
            comment.id === val.id ? val : comment
          ),
        };
        setState({
          ...state,
          activeStep: activeStepUpdated,
          steps: state.steps.map((step) =>
            step.uuid === activeStep.uuid ? activeStepUpdated : step
          ),
        });
        return true;
      case 'delete':
        setState({
          ...state,
          activeStep: {
            ...state.activeStep,
            comments: state.activeStep.comments.filter(({ id }) => id !== val.id),
          },
          steps: state.steps.map((step) =>
            step.uuid === activeStep.uuid
              ? {
                  ...state.activeStep,
                  comments: state.activeStep.comments.filter(({ id }) => id !== val.id),
                }
              : step
          ),
        });
        return true;
      default:
        return false;
    }
  };

  const onboardinglikeDislike = (val) => {
    onboardinglikeDislikeApi(activeStep.uuid, { like: val }).then((response) => {
      if (response.statusCode === 200) {
        setState({
          ...state,
          activeStep: response.data,
          steps: state.steps.map((step) =>
            step.uuid === response.data.uuid ? response.data : step
          ),
        });
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };

  const handleMarkAsRead = (content) => {
    markAsReadApi(content.uuid, { time_duration: time }).then((response) => {
      if (response.statusCode == 200) {
        setState({
          ...state,
          activeStep: response.data ? response.data : activeStep,
          steps: state.steps.map((step) => (step.uuid === activeStep.uuid ? response.data : step)),
        });
        props.setTime(0);
        setSeconds(activeStep.uuid, 0);
      }
    });
  };

  return (
    <>
      {loader ? (
        <Shimmer type="OnboardingLanding" />
      ) : (
        <div className={styles.dashboard}>
          <DashboardHeader
            dashboardLogo={imageBasePath + 'onboarding_icon.svg'}
            title="Onboarding"
            progress={{
              totalSteps: steps.filter((step) => step.is_required)?.length,
              completedSteps: steps.filter((step) => step.is_required && step.completed_at)?.length,
            }}
            steps={state.steps}
          />
          {steps.length ? (
            <OnboardingLibrary
              steps={steps}
              handleChangeStep={handleChangeStep}
              activeStep={activeStep}
              isAdmin={false}
              handleMarkAsComplete={handleMarkAsComplete}
              updateActiveStep={updateActiveStep}
              onboardinglikeDislike={onboardinglikeDislike}
              handleMarkAsRead={handleMarkAsRead}
              setShowModal={setShowModal}
              showModal={showModal}
              time={time}
              setTime={setTime}
            />
          ) : (
            <NoResult
              image="onboarding_no_state.svg"
              title="Onboarding not available"
              subtext="Seems onboarding module is not yet published, kindly check later"
            />
          )}
        </div>
      )}
      <FeedbackDialog
        type=""
        title={`Great job, you have completed the Onboarding process!`}
        subtitle={`Please give us your rating on the Onboarding experience`}
        open={openFeedback}
        handleSubmit={handleSubmitFeedback}
        handleClose={() => {
          setOpenFeedback(false);
        }}
        loader={feedbackSubmitLoader}
      />
    </>
  );
}
