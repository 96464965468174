import { useMemo } from 'react';
import Organization from 'infrastructure/Organization';
import { useGetFF } from 'hooks/useGetFF';

export const useShouldShowIAFeature = (flag_id) => {
  const org_id = Organization.getSelectedOrg()?.slug;
  const { isEnabled, value } = useGetFF({ flag_id });
  const ai_integration = Organization.getSelectedOrg()?.ai_integration;

  const enabled = useMemo(() => {
    if (isEnabled && ai_integration && value !== '') {
      if (!value?.checkBySlug) return true;
      return value?.slugs?.includes(org_id) ?? false;
    }
    return false;
  }, [isEnabled, ai_integration, org_id, value]);

  return enabled;
};
