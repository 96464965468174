import React, { useMemo } from 'react';
import RatingCriteriaTooltip from '../Common/RatingCriteriaTooltip';
import RoleplayRating from '../Common/RoleplayRating/RoleplayRating';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import classes from './NewRatingCriteriaDescription.module.scss';
import { useAutoFillStore } from '../Autorating/AutoFill.store';
import { Collapse, withStyles } from '@material-ui/core';
import classNames from 'classnames';

const ShowRatingCriteriaIA = ({ selectedScenario }) => {
  const secondAttempt = useRoleplayStore((state) => state?.secondAttempt);
  const showDetails = useAutoFillStore((state) => state?.showDetails);
  const openShowDetails = useAutoFillStore((state) => state?.openShowDetails);
  const closeShowDetails = useAutoFillStore((state) => state?.closeShowDetails);

  const StyledCollapse = withStyles({
    root: {
      border: showDetails ? '1px solid #CFD8DC' : null,
      borderRadius: showDetails ? '0.5rem' : null,
      padding: showDetails ? '5px 12px 14px 12px' : null,
      display: showDetails ? 'flex' : null,
      flexDirection: showDetails ? 'column' : null,
      gap: showDetails ? '1rem' : null,
    },
  })(Collapse);

  const areSuggestionsNull1Attempt = useMemo(() => {
    return selectedScenario?.response?.rating_criterials.every(
      ({ reasoning, improvement_suggestion }) =>
        reasoning === null && improvement_suggestion === null
    );
  }, [selectedScenario]);

  const areSuggestionsNull2Attempt = useMemo(() => {
    return selectedScenario?.response_retake?.rating_criterials.every(
      ({ reasoning, improvement_suggestion }) =>
        reasoning === null && improvement_suggestion === null
    );
  }, [selectedScenario]);

  const shouldShow = useMemo(() => {
    return (
      (!secondAttempt && !areSuggestionsNull1Attempt) ||
      (secondAttempt && !areSuggestionsNull2Attempt)
    );
  }, [secondAttempt, areSuggestionsNull1Attempt, areSuggestionsNull2Attempt]);

  return (
    <div>
      {shouldShow ? (
        <div className={classes.headContainer}>
          <RatingCriteriaTooltip />
          {showDetails ? (
            <div className={classes.action} onClick={() => closeShowDetails()}>
              Hide details
            </div>
          ) : (
            <div className={classes.action} onClick={() => openShowDetails()}>
              Show details
            </div>
          )}
        </div>
      ) : (
        <RatingCriteriaTooltip />
      )}
      {!secondAttempt && (
        <div className={classNames({ [classes.globalContainer]: showDetails })}>
          {selectedScenario?.response?.rating_criterials?.map(
            ({ rating_criteria, stars, reasoning, improvement_suggestion }, index) => (
              <StyledCollapse in={showDetails} collapsedSize={25} key={index}>
                <div className={classes.rating}>
                  <span>{rating_criteria?.name}</span>
                  <RoleplayRating withTooltip stars={stars} />
                </div>
                <div>
                  <p className="heading7Bold" style={{ marginTop: '0.25rem' }}>
                    Why this rating?
                  </p>
                  <p className="heading7" style={{ marginTop: '0.25rem' }}>
                    {reasoning}
                  </p>
                </div>
                <div>
                  <p className="heading7Bold" style={{ marginTop: '0.25rem' }}>
                    How to improve?
                  </p>
                  <p className="heading7" style={{ marginTop: '0.25rem' }}>
                    {improvement_suggestion}
                  </p>
                </div>
              </StyledCollapse>
            )
          )}
        </div>
      )}
      {secondAttempt && selectedScenario.response_retake !== null && (
        <div className={classNames({ [classes.globalContainer]: showDetails })}>
          {selectedScenario?.response_retake?.rating_criterials?.map(
            ({ rating_criteria, stars, reasoning, improvement_suggestion }, index) => (
              <StyledCollapse in={showDetails} collapsedSize={25} key={index}>
                <div className={classes.rating}>
                  <span>{rating_criteria?.name}</span>
                  <RoleplayRating withTooltip stars={stars} />
                </div>
                <div>
                  <p className="heading7Bold" style={{ marginTop: '0.25rem' }}>
                    Why this rating?
                  </p>
                  <p className="heading7" style={{ marginTop: '0.25rem' }}>
                    {reasoning}
                  </p>
                </div>
                <div>
                  <p className="heading7Bold" style={{ marginTop: '0.25rem' }}>
                    How to improve?
                  </p>
                  <p className="heading7" style={{ marginTop: '0.25rem' }}>
                    {improvement_suggestion}
                  </p>
                </div>
              </StyledCollapse>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default ShowRatingCriteriaIA;
