import React, { useEffect, useRef, useState } from 'react';
import styles from './chatAssist.module.scss';
import PromptContainer from './PromptContainer/PromptContainer';
import MessagesContainer from './MessagesContainer/MessagesContainer';
import ChatInput from './ChatInput/ChatInput';
import IconButton from './IconButton/IconButton';
import Loader from './Loader/Loader';
import { msgInfo, tipsText, errorResponse } from './constants';
import { useAskQuestionMutation } from '../ChatAssist.queries';
import { useSalesAssistStore } from '../ChatAssist.store';
import { ArrowDownward } from '@material-ui/icons';
import classNames from 'classnames';

const ChatAssistView = () => {
  const messagesContainerRef = useRef(null);
  const firstQuestionRef = useRef(null);
  const firstResponseRef = useRef(null);
  const [value, setValue] = useState('');
  const [showScrollButton, setShowScrollButton] = useState(false);

  const messages = useSalesAssistStore((state) => state?.messages);
  const addMessage = useSalesAssistStore((s) => s?.updateMessages);
  const addID = useSalesAssistStore((s) => s?.setId);
  const id = useSalesAssistStore((state) => state?.session_id);

  const { mutate: askQuestion, isLoading: isWaiting } = useAskQuestionMutation();

  const isJustSpaces = (m) => m.trim() === '';

  const onChange = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  const handleNewMessages = (message, type = msgInfo?.sender) => {
    if (isJustSpaces(message)) return;
    const newMessage = {
      text: message,
      type,
    };
    addMessage(newMessage);
    const body = id === '' ? { prompt: message } : { prompt: message, session_id: id };

    askQuestion(body, {
      onSuccess: (response) => {
        const newResponse = {
          text: response?.answer,
          type: msgInfo?.recipient,
        };
        if (id === '') addID(response?.session_id);
        addMessage(newResponse);
      },
      onError: () => {
        addMessage(errorResponse);
      },
    });
    setValue('');
  };

  const handleSendMessage = () => {
    handleNewMessages(value, msgInfo?.sender);
  };

  const handleScroll = () => {
    const container = messagesContainerRef.current;
    if (container) {
      const { scrollTop } = container;

      if (scrollTop < 0) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    }
  };

  const scrollToBottom = () => {
    const container = messagesContainerRef.current;
    if (container) {
      container.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const container = messagesContainerRef.current;
    const scrollHandler = () => handleScroll();
    if (container) {
      container.addEventListener('scroll', scrollHandler);
      handleScroll();
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', scrollHandler);
      }
    };
  }, []);

  useEffect(() => {
    if (messages.length === 0) return;

    const lastMessage = messages[0];

    if (lastMessage.type === msgInfo.sender && firstQuestionRef.current) {
      firstQuestionRef.current.scrollIntoView({ block: 'start' });
    } else if (lastMessage.type === msgInfo.recipient && firstResponseRef.current) {
      firstResponseRef.current.scrollIntoView({ block: 'start' });
    }
  }, [messages]);

  return (
    <div className={styles.chatContainer}>
      <div
        className={classNames(styles.messagesContainer, {
          [styles.hidden]: Object.keys(messages).length === 0,
        })}
        ref={messagesContainerRef}
      >
        <MessagesContainer
          messages={messages}
          firstQuestionRef={firstQuestionRef}
          firstResponseRef={firstResponseRef}
          isWaiting={isWaiting}
        />
      </div>
      {showScrollButton && (
        <button className={styles.scrollButton} onClick={scrollToBottom}>
          <ArrowDownward />
        </button>
      )}
      {Object.keys(messages).length === 0 && (
        <div className={styles.promptContainer}>
          {tipsText.map((item, index) => (
            <PromptContainer key={index} title={item?.title} text={item?.text} />
          ))}
        </div>
      )}
      {isWaiting && (
        <div className={styles.loadMessageContainer}>
          <Loader />
        </div>
      )}
      <div className={styles.inputContainer}>
        <ChatInput
          value={value}
          onChange={onChange}
          onKeyPress={handleSendMessage}
          disabled={isWaiting}
        />
        <IconButton
          onClick={handleSendMessage}
          disabled={isWaiting || value === '' || isJustSpaces(value)}
        />
      </div>
    </div>
  );
};

export default ChatAssistView;
