import React from 'react';
import styles from '../chatAssist.module.scss';
import { msgInfo } from '../constants';
import { ReactHtmlParser } from 'shared_ui_components';
const MessagesContainer = ({ messages, firstQuestionRef, firstResponseRef }) => {
  return messages.map((msg, index) => {
    const isFirstQuestion = index === 0 && msg.type === msgInfo.sender;
    const isFirstResponse = index === 0 && msg.type === msgInfo.recipient;
    return (
      <div
        key={index}
        ref={isFirstQuestion ? firstQuestionRef : isFirstResponse ? firstResponseRef : null}
        className={msg.type === msgInfo?.recipient ? styles.messageIA : styles.messageUser}
      >
        {msgInfo?.recipient === msg.type ? (
          <div className={styles.containerHtml}>
            <ReactHtmlParser content={msg.text} isFromChatAssist />{' '}
          </div>
        ) : (
          msg.text
        )}
      </div>
    );
  });
};

export default MessagesContainer;
