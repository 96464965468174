import React, { useMemo } from 'react';
import parse, { domToReact } from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';

const ReactHtmlParser = ({ content, isStreamContent = false, isFromChatAssist = false }) => {
  const html = useMemo(() => {
    if (typeof content === 'string') return content;
    try {
      return content?.toString();
    } catch (error) {
      return JSON.stringify(content || '');
    }
  }, [content]);

  if (isFromChatAssist)
    return (
      <>
        {parse(
          sanitizeHtml(html, {
            allowedTags: allowedChatTags,
            allowedAttributes: {
              div: ['class'],
              a: ['href', 'target', 'rel'],
              img: ['src'],
            },
          }),
          {
            replace: (domNode) => {
              if (domNode.name === 'blockquote') {
                const text = domToReact(domNode.children);
                const content = domNode.children
                  .map((child) => child.data || '')
                  .join('')
                  .trim();
                const hasQuotes = /^".*"$/.test(content);
                const containsExampleEmail = domNode.children.some(
                  (child) =>
                    child.name === 'cite' &&
                    child.children?.some((c) => c.data === 'Example Email:')
                );

                if (!hasQuotes && !containsExampleEmail) return <blockquote>{text}</blockquote>;
                const blockquoteStyle = containsExampleEmail
                  ? {
                      borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    }
                  : { fontStyle: 'oblique', margin: '0.3rem' };
                return <blockquote style={blockquoteStyle}>{text}</blockquote>;
              }
              if (domNode.name === 'h2') {
                const text = domToReact(domNode.children);
                return (
                  <>
                    <hr />
                    <h2>{text}</h2>
                  </>
                );
              }
            },
          }
        )}
      </>
    );

  if (isStreamContent)
    return (
      <>
        {parse(
          sanitizeHtml(html, {
            allowedTags: allowedStreamTags,
            allowedAttributes: {
              div: ['class'],
              a: ['href', 'target', 'rel'],
              img: ['src'],
            },
          })
        )}
      </>
    );
  return (
    <>
      {parse(
        sanitizeHtml(html, {
          allowedTags: ['iframe', 'ul', 'li'],
          allowedAttributes: {
            iframe: ['src'],
          },
        })
      )}
    </>
  );
};

export default ReactHtmlParser;

const allowedStreamTags = [
  'div',
  'ul',
  'li',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'p',
  'br',
  'hr',
  'a',
  'img',
];

const allowedChatTags = [
  'div',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'p',
  'b',
  'i',
  'u',
  'strong',
  'a',
  'em',
  'mark',
  'ul',
  'li',
  'br',
  'hr',
  'code',
  'img',
  'ul',
  'ol',
  'li',
  'q',
  'table',
  'thead',
  'tbody',
  'tr',
  'td',
  'th',
  'blockquote',
  'cite',
];
