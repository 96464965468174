import { List, ListItem } from '@material-ui/core';
import React from 'react';
import { CustomCheckbox, CustomTooltipWhite } from 'shared_ui_components';

const MandatoryModules = ({ settings, onChangeOnboarding, onChangeTraining }) => {
  return (
    <List className="listInline">
      <ListItem className="listInlineItem">
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <span className="supportText">Required step(s)</span>
          <CustomTooltipWhite
            tooltipIcon="info_icon.svg"
            title="These modules are mandated for sales rep in order to do their first check-in"
          />
        </div>
      </ListItem>
      <ListItem className="listInlineItem">
        <CustomCheckbox
          label="Onboarding"
          value={settings.is_onboarding_enabled}
          onChange={(val) => onChangeOnboarding(val)}
        />
      </ListItem>
      <ListItem className="listInlineItem">
        <CustomCheckbox
          label="Training"
          value={settings.is_training_enabled}
          onChange={(val) => onChangeTraining(val)}
        />
      </ListItem>
    </List>
  );
};

export default MandatoryModules;
